import disclosure       from '@/pages/about/disclosure'
import greeting         from '@/pages/about/greeting'
import ksa              from '@/pages/about/ksa'
import rules            from '@/pages/about/rules'
import organization     from '@/pages/about/organization'

export default [
    {
        path: '/about-greeting', 
        name: 'about',
        component: greeting,
    },
    {
        path: '/about-ksa',
        name: 'ksa',
        component: ksa
    },
    {
        path: '/about-organization',
        name: 'organization',
        component: organization
    },
    {
        path: '/about-rules',
        name: 'rules',
        component: rules
    },
    {
        path: '/about-disclosure',
        name: 'disclosure',
        component: disclosure
    }
]
