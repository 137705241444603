<template>
    <!--메인-->
    <div class="main">
        <!--메인 슬라이더-->
        <!-- <Slider /> -->

        <!--관련단체-->
        <div class="main-section wrap-sm">
            <ul class="organizations">
                <li><a href="https://www.sports.or.kr" target="_blank"><img src="@/assets/images/img/logo01.png"></a></li>
                <li><a href="https://olympics.com" target="_blank"><img src="@/assets/images/img/logo02.png"></a></li>
                <li><a href="https://isasurf.org" target="_blank"><img src="@/assets/images/img/logo03.png"></a></li>
                <li><a href="https://asiansurfing.org" target="_blank"><img src="@/assets/images/img/logo04.png"></a></li>
            </ul>
        </div>


        <!--KSA Festival-->
        <div class="main-section wrap-md">
            <div class="title-1">
                <p>대회정보</p>
                <span>최신 대회정보를 확인하세요</span>
            </div>
            <div class="events float-wrap">
                <router-link v-for="(vo, index) of matchList" :key="index"
                    :to="'/match-view?no=' + vo.bdNo + '&boardType=' + vo.bdTypeCd">
                    <img :src="apiBaseUrl + vo?.bdThumbImg1">
                </router-link>
            </div>
        </div>

        <!-- 자격신청 -->
        <div class="main-section wrap-xs">
            <div class="title-1">
                <p>자격신청</p>
                <span>대한서핑협회 자격증 정보를 확인할 수 있습니다.</span>
            </div>
            <ul class="news" v-for="(vo, index) of developmentList" :key="index">
                <li>
                    <router-link :to="'/development-view?no=' + vo.bdNo + '&boardType=' + vo.bdTypeCd">
                        <p>
                            <em>{{ vo.bdTitle }}</em>
                        </p>
                        <span>{{ vo.regDt }}</span>
                    </router-link>
                </li>
            </ul>
            <router-link to="/development-list" class="more-bottom">더보기 +</router-link>
        </div>

        <!--뉴스 공지-->
        <div class="main-section wrap-xs">
            <div class="title-1">
                <p>뉴스 공지</p>
                <span>최신 뉴스와 공지사항을 확인하세요</span>
            </div>
            <ul class="news" v-for="(vo, index) of noticeList" :key="index">
                <li>
                    <router-link :to="'/notice-view?no=' + vo.bdNo + '&boardType=' + vo.bdTypeCd">
                        <p>
                            <em>{{ vo.bdTitle }}</em>
                        </p>
                        <span>{{ vo.regDt }}</span>
                    </router-link>
                </li>
            </ul>
            <router-link to="/notice-list" class="more-bottom">더보기 +</router-link>
        </div>

        <!--갤러리-->
        <div class="main-section main-section-accent">
            <div class="wrap-sm">
                <div class="title-1">
                    <p>갤러리</p>
                    <span>대한서핑협회 활동을 사진으로 확인하세요</span>
                </div>
                <!--갤러리-->
                <div class="gallery gallery-main">
                    <div class="gallery-box" v-for="(item, index) in galleryList" :key="item"
                        :class="'box-' + ((index % 7) + 1)" @click="openModal(item)">
                        <div class="gallery-desc">
                            <span>{{ item?.bdTitle }}</span>
                        </div>
                        <img :src="apiBaseUrl + item?.bdThumbImg1">
                    </div>
                </div>
                <!--갤러리 팝업-->
                <Modal :photo="selectedPhoto" :dataList="galleryList" :visible="modalVisible" @close="closeModal"
                    @chgPhoto="chgPhoto" />
            </div>
        </div>
    </div>
</template>

<script setup>
import Slider from '@/components/slider'
import Modal from '@/components/common/modal/modal'
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const apiBaseUrl = process.env.VUE_APP_API_URL;

const matchList = computed(() => {
    return store.state.temp.main.eventList
})

const developmentList = computed(() => {
    return store.state.temp.main.developmentList
})

const noticeList = computed(() => {
    return store.state.temp.main.noticeList
})

const galleryList = computed(() => {
    return store.state.temp.main.galeryList
})

const getMainData = async () => {
    await store.dispatch('temp/fetchMainData')
}

const modalVisible = ref(false);
const selectedPhoto = ref(null);

const openModal = async (photo) => {
    selectedPhoto.value = photo;
    modalVisible.value = true;
}

const closeModal = () => {
    modalVisible.value = false;
};

const chgPhoto = (photo) => {
    selectedPhoto.value = photo;
};

onMounted(async () => {
    getMainData()
})
</script>

<style></style>