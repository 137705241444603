import { get } from './axios'
import { post } from './axios'

export const getOutInfPersonAjax = async (params) => {
    const { status, data } = await post("/pvt/common/getOutInfPersonAjax", params)

    if (status === 200 || status === 201) {
       return data
    }
}

export const loginAjax = async (params) => {
    const { status, data } = await post("/user/login", params)

    if (status === 200 || status === 201) {
       return data
    }
}

export const getMainData = async () => {
    const { status, data } = await get("/main")

    if (status === 200 || status === 201) {
       return data
    }
}
