<template>
    <aside class="asidemenu">
    </aside>
</template>

<script>

export default {
}
</script>

<style>

</style>