<template>
    <div class="gallery-popup" v-if="visible">
        <div class="gallery-pop">
            <div class="gallery-pop-box">
                <div class="gallery-pop-cont">
                    <button type="button" class="gallery-pop-exit" @click="closeModal">
                        <img src="@/assets/images/icon/icon-x-white.png" alt="닫기">
                    </button>
                    <div class="gallery-slide">
                        <button type="button" class="gallery-slide-prev"
                                @click="goPrev"
                                :disabled="currentIndex === 0">
                            <img src="@/assets/images/icon/icon-prev-white-sm.png" alt="이전"></button>
                        <div class="gallery-slide-img">
                            <img :src="apiBaseUrl + photo.bdThumbImg1">
                        </div>
                        <button type="button" class="gallery-slide-next"
                                @click="goNext"
                                :disabled="currentIndex === dataList.length - 1">
                            <img src="@/assets/images/icon/icon-next-white-sm.png" alt="다음"></button>
                    </div>
                    <button class="btn-sm btn-primary" @click="upd(photo.bdNo)" v-if="isAdmin">수정</button>
                    <button class="btn-sm btn-primary" @click="del(photo.bdNo)" v-if="isAdmin">삭제</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { watch } from 'vue';
import { ref } from 'vue';
import { useStore } from 'vuex';

const store = useStore()
const isAdmin = computed (() => {
    return store.state.temp.isAdmin
})
const apiBaseUrl = process.env.VUE_APP_API_URL;
const props = defineProps({
    photo: Object,
    dataList: Array,
    visible: Boolean
});

// const currentIndex = ref(props.dataList.indexOf(props.photo));
const currentIndex = ref(0);
const emit = defineEmits(['close', 'chgPhoto', 'updateGallery', 'deleteGallery']);

const closeModal = () => {
    emit("close");
};


const goPrev = () => {
    currentIndex.value = Math.max(currentIndex.value - 1, 0);
    emit("chgPhoto", props.dataList[currentIndex.value]);
};

const goNext = () => {
    currentIndex.value = Math.min(currentIndex.value + 1, props.dataList.length - 1);
    emit("chgPhoto", props.dataList[currentIndex.value]);
};

const upd = (bdNo) => {
    emit('updateGallery', bdNo)
}

const del = async (bdNo) => {
    emit('deleteGallery', bdNo)
}

watch(() => props.photo, (newVal) => {
    currentIndex.value = props.dataList.indexOf(newVal);
});
</script>

<style scoped></style>
