import level from       '@/pages/development/level'
import coaching from       '@/pages/development/coaching'
import rescue from       '@/pages/development/rescue'
import list from       '@/pages/development/list'
import view from       '@/pages/development/view'

export default [
    {
        path: '/development-level',
        name: 'development-level',
        component: level
    },
    {
        path: '/development-coaching',
        name: 'development-coaching',
        component: coaching
    },
    {
        path: '/development-rescue',
        name: 'development-rescue',
        component: rescue
    },
    {
        path: '/development-list',
        name: 'development-list',
        component: list
    },
    {
        path: '/development-view',
        name: 'development-view',
        component: view
    }
]
