import list from '@/pages/memberBoard/list'
import join from '@/pages/memberBoard/join'
import view from '@/pages/memberBoard/view'
import fee from '@/pages/memberBoard/fee'
import sponsor from '@/pages/memberBoard/sponsor'
export default [
  {
    path: "/memberBoard-list",
    name: "memberBoard-list",
    component: list,
  },
  {
    path: "/memberBoard-join",
    name: "memberBoard-join",
    component: join,
  },
  {
    path: "/memberBoard-fee",
    name: "memberBoard-fee",
    component: fee,
  },
  {
    path: "/memberBoard-view",
    name: "memberBoard-view",
    component: view,
  },
  {
    path: "/memberBoard-sponsor",
    name: "memberBoard-sponsor",
    component: sponsor,
  },
];
