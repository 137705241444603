<template>
  <div class="footer">
        	<div class="footer-wrap wrap-sm float-wrap">
        		<img  class="footer-logo" src="@/assets/images/common/logo.png">
        		<div class="footer-links">
        			<p>FOLLOW US</p>
        			<a href="#"><img src="@/assets/images/icon/icon-ig.png" alt="Instagram"></a>
        			<a href="#"><img src="@/assets/images/icon/icon-fb.png" alt="Facebook"></a>
        			<a href="#"><img src="@/assets/images/icon/icon-youtube.png" alt="Youtube"></a>
        		</div>
        		<div class="footer-info float-wrap">
        			<div class="footer-info-box">
        				<span>&nbsp;</span>
        				<p>부산광역시 해운대구 해운대해변로203 오션타워 1828호</p>
        			</div>
        			<div class="footer-info-box">
        				<span>&nbsp;</span>
        				<p><a href="mailto:info@koreasurfing.org">info@koreasurfing.org</a></p>
        			</div>
        			<ul class="footer-info-box">
        				<li>
        					<span><em>&nbsp;</em></span>
        					<p></p>
        				</li>
        				<li>
        					<span></span>
        					<p>+82-51-740-5425</p>
        				</li>
        			</ul>
        		</div>
        	</div>
        	<p class="footer-copy"><em>Copyright ⓒ Korea Surfing Association All right reserved.</em></p>
        </div>
</template>

<script>
export default {

}
</script>

<style>

</style>