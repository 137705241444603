import { get, post } from './axios'

export const getUserDetail = async (params) => {
    const { status, data } = await get("/user/detail", params)

    if (status === 200 || status === 201) {
        return data
    }
}

export const userJoin = async (params) => {
    const { status, data } = await post("/user/join", params)

    if (status === 200 || status === 201) {
        return data
    }
}

export const userUpdate = async (params) => {
    const { status, data } = await post("/user/update", params)

    if (status === 200 || status === 201) {
        return data
    }
}