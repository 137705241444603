<template>
    <!--하단-->
    <div class="board-bottom float-wrap">
        <div class="board-bottom-left">
            <div class="page-total">
                <span>페이지</span>
                <em>{{pagingBean?.pageStartNo > 0 ? pagingBean?.pageNo : 0}}/{{pagingBean?.totalPageCount}}</em>
            </div>
        </div>
        <div class="board-bottom-middle">
            <div class="paging">
                <div class="paging-btns">
                    <button type="button" class="direction" v-if="pagingBean?.pageNo > 1" @click="gridLoad(1)">
                        <img src="@/assets/images/icon/icon-paging-first.png" alt="처음">
                    </button>
                    <button type="button" class="direction" v-if="pagingBean?.pageStartNo -1 > 1" @click="gridLoad(pagingBean?.pageStartNo -1)">
                        <img src="@/assets/images/icon/icon-paging-prev.png" alt="이전">
                    </button>
                    <button v-for="num of pageGroupArr" :key="num" type="button" :class="pagingBean?.pageNo == num ? 'on' : ''" @click="gridLoad(num)">
                        {{ num }}
                    </button>
                    <button type="button" class="direction" v-if="pagingBean?.pageEndNo + 1 < pagingBean?.totalPageCount" @click="gridLoad(pagingBean?.pageEndNo + 1)">
                        <img src="@/assets/images/icon/icon-paging-next.png" alt="다음">
                    </button>
                    <button type="button" class="direction" v-if="pagingBean?.pageNo < pagingBean?.totalPageCount" @click="gridLoad(pagingBean?.totalPageCount)" >
                        <img src="@/assets/images/icon/icon-paging-last.png" alt="마지막">
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

    const store = useStore()

    const emit = defineEmits(['getApplyList'])

    const pagingBean = computed(() => {
        return store.state.admin.pagination 
    })

    const pageGroupArr = computed(() => {
        const arr = []
        const start = store.state.admin.pagination.pageStartNo
        const end = store.state.admin.pagination.pageEndNo
        if(start > 0) {
            for (let i = start ; i <= end ; i++) {
                arr.push(i)
            }
        }
        
        return arr
    })

    const gridLoad = (pageNo) => {
        store.commit('temp/setSearchParamForApply', {key : 'pageNo', value : pageNo})
        emit('getApplyList')
    }

    const memberExcelDownload = () => {
        alert("구현중")
    }

</script>

<style>

</style>