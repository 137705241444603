<template>

    <!--회원가입-->
    <div class="member join">
        <div class="member-bg">
            <div class="member-box">
                <div class="title-2">
                    <p>회원가입</p>
                    <span>정보를 입력 하고 회원가입 하실 수 있습니다.</span>
                </div>
                <div class="member-inputs">

                    <!--아이디-->
                    <div class="input-box">
                        <label>아이디</label>
                        <customInput type="text" name="userId" placeholder="아이디를입력하세요" @updateInput="updateInput"/>
                    </div>

                    <!--비밀번호-->
                    <div class="input-box">
                        <label>비밀번호</label>
                        <customInput type="password" name="password" placeholder="비밀번호입력하세요" @updateInput="updateInput"/>
                    </div>

                    <!--비밀번호 확인-->
                    <div class="input-box">
                        <label>비밀번호 확인</label>
                        <customInput type="password" name="passwordChk" placeholder="비밀번호 확인" @updateInput="updateInput"/>
                    </div>

                    <!--이름-->
                    <div class="input-box">
                        <label>이름</label>
                        <customInput type="text" name="userName" placeholder="이름을 입력하세요" @updateInput="updateInput"/>
                    </div>

                    <!--이메일-->
                    <div class="input-box">
                        <label>이메일</label>
                        <customInput type="text" name="userEmail" placeholder="이메일 주소를 입력하세요" @updateInput="updateInput"/>
                    </div>

                    <!-- 시도협회 -->
                    <div class="input-box">
                        <label>시도협회</label>
                        <select class="input-box" style="width: 200px;" v-model="selectedOption" @change="updateSelect">
                            <option value="서울특별시">서울특별시</option>
                            <option value="경기도">경기도</option>
                            <option value="인천광역시">인천광역시</option>
                            <option value="강원특별자치도">강원특별자치도</option>
                            <option value="충청북도">충청북도</option>
                            <option value="충청남도">충청남도</option>
                            <option value="대전광역시">대전광역시</option>
                            <option value="세종특별자치시">세종특별자치시</option>
                            <option value="전라북도">전라북도</option>
                            <option value="전라남도">전라남도</option>
                            <option value="광주광역시">광주광역시</option>
                            <option value="경상북도">경상북도</option>
                            <option value="경상남도">경상남도</option>
                            <option value="대구광역시">대구광역시</option>
                            <option value="울산광역시">울산광역시</option>
                            <option value="부산광역시">부산광역시</option>
                            <option value="제주특별자치도">제주특별자치도</option>
                            <option value="KSA">KSA</option>
                            <option value="기타">기타</option>
                        </select>
                    </div>

                    <!--핸드폰-->
                    <div class="input-box input-box-phone">
                        <label>핸드폰</label>
                        <div class="input-box-line">
                            <customInput type="text" name="phone1" maxlength="3" placeholder="010" @updateInput="updateInput"/>
                            <customInput type="text" name="phone2" maxlength="4" placeholder="0000" @updateInput="updateInput"/>
                            <customInput type="text" name="phone3" maxlength="4" placeholder="0000" @updateInput="updateInput"/>
                        </div>
                    </div>

                    <!--성별-->
                    <div class="radio-wrap radio-wrap-vertical">
                        <span class="radio-label">성별</span>
                        <div class="radio-boxes">
                            <div class="radio-box">
                                <searchRadio 
                                    name="gender" 
                                    :radioId="'gender_m'"
                                    :title="'남자'"
                                    :radioValue="'M'"
                                    @updateRadio="updateInput"
                                    />
                            </div>
                            <div class="radio-box">
                                <searchRadio 
                                    name="gender" 
                                    :radioId="'gender_f'"
                                    :title="'여자'"
                                    :radioValue="'F'"
                                    @updateRadio="updateInput"
                                    />
                            </div>
                        </div>
                    </div>

                    <!-- 등급 -->
                    <div class="radio-wrap radio-wrap-vertical">
                        <span class="radio-label">등급</span>
                        <div class="radio-boxes">
                            <div class="radio-box">
                                <searchRadio 
                                    name="userLevel" 
                                    :radioId="'rank_normal'"
                                    :title="'일반회원'"
                                    :radioValue="1"
                                    @updateRadio="updateInput"
                                    />
                            </div>
                            <div class="radio-box">
                                <searchRadio 
                                    name="userLevel" 
                                    :radioId="'rank_regular'"
                                    :title="'정회원(가맹점)'"
                                    :radioValue="4"
                                    @updateRadio="updateInput"
                                    />
                            </div>
                            <div class="radio-box">
                                <searchRadio 
                                    name="userLevel" 
                                    :radioId="'rank_player'"
                                    :title="'선수'"
                                    :radioValue="2"
                                    @updateRadio="updateInput"
                                    />
                            </div>
                            <div class="radio-box">
                                <searchRadio 
                                    name="userLevel" 
                                    :radioId="'rank_order'"
                                    :title="'지도자'"
                                    :radioValue="3"
                                    @updateRadio="updateInput"
                                    />
                            </div>
                            <div class="radio-box">
                                <searchRadio 
                                    name="userLevel" 
                                    :radioId="'rank_judge'"
                                    :title="'심판'"
                                    :radioValue="4"
                                    @updateRadio="updateInput"
                                    />
                            </div>
                        </div>
                    </div>

                    <!--메일링 서비스-->
                    <div class="radio-wrap">
                        <span class="radio-label">메일링 서비스</span>
                        <div class="radio-boxes">
                            <div class="radio-box">
                                <searchRadio 
                                    name="mailYn" 
                                    :radioId="'mailYn_y'"
                                    :title="'수신'"
                                    :radioValue="'Y'"
                                    @updateRadio="updateInput"
                                    />
                            </div>
                            <div class="radio-box">
                                <searchRadio 
                                    name="mailYn" 
                                    :radioId="'mailYn_n'"
                                    :title="'비수신'"
                                    :radioValue="'N'"
                                    @updateRadio="updateInput"
                                    />
                            </div>
                        </div>
                    </div>

                    <!--문자 수신 여부-->
                    <div class="radio-wrap">
                        <span class="radio-label">문자 수신 여부</span>
                        <div class="radio-boxes">
                            <div class="radio-box">
                                <searchRadio 
                                    name="msgYn" 
                                    :radioId="'msgYn_y'"
                                    :title="'수신'"
                                    :radioValue="'Y'"
                                    @updateRadio="updateInput"
                                    />
                                </div>
                                <div class="radio-box">
                                <searchRadio 
                                    name="msgYn" 
                                    :radioId="'msgYn_n'"
                                    :title="'비수신'"
                                    :radioValue="'N'"
                                    @updateRadio="updateInput"
                                    />
                            </div>
                        </div>
                    </div>

                    <!--정보공개-->
                    <div class="radio-wrap">
                        <span class="radio-label">정보공개</span>
                        <div class="radio-boxes">

                            <div class="radio-box">
                                <searchRadio 
                                    name="infoYn" 
                                    :radioId="'infoYn_y'"
                                    :title="'공개'"
                                    :radioValue="'Y'"
                                    @updateRadio="updateInput"
                                    />
                            </div>
                            <div class="radio-box">
                                <searchRadio 
                                    name="infoYn" 
                                    :radioId="'infoYn_n'"
                                    :title="'비공개'"
                                    :radioValue="'N'"
                                    @updateRadio="updateInput"
                                    />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="input-btns">
                    <button type="button" class="btn btm-btn accent" @click="join">회원 가입</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router'
    import { onMounted, ref } from 'vue';
    import customInput from '@/components/common/input/customInput.vue'
    import searchRadio from '@/components/common/radio/searchRadio.vue'
    import { userJoin } from '@/api/member'
    import { delCookie, setCookie } from '@/util/util'

    const store = useStore()
    const router = useRouter()        
    const selectedOption = ref(''); 

    const updateInput = (newValue) => {
        store.commit('member/updateMember', newValue)
    }
    
    const updateSelect = () => {
        store.state.member.member.userArea = selectedOption.value;
    }

    // 회원가입
    const join = async () => {
        let chk = store.state.member.member
        for(let key in chk) {
            if(chk[key] == '') {
                // alert(key+'가 비어있습니다')
            }
        }

        store.state.member.member.userHp = chk.phone1 + chk.phone2 + chk.phone3

        let rtn = {}
        rtn = await userJoin(store.state.member.member)
        alert(rtn.resultMsg)
        if(rtn?.result == 'ok') {
            delCookie('sessionId')
            setCookie('sessionId', rtn?.sessionId, 3)
            store.commit('temp/setIsLogin', true)
            store.commit('temp/setUserInfo', {key : 'userId', value: chk?.userId})
            store.commit('temp/setUserInfo', {key : 'userName', value: chk?.userName})
            store.commit('temp/setUserInfo', {key : 'userLevel', value: chk?.userLevel})

            store.commit('member/clearMember')
            router.push({
                name: 'Home'
            }) 
        }
    }
    
    
    onMounted(() => {
        store.commit('member/clearMember')
    })

</script>

<style></style>