import { deleteUser, updateUserLevel, getApplyList, cancelApply } from '@/api/admin'
import adminApply from '@/models/gridInfo/adminApply.json'
export default {
    namespaced: true, 
    state: {
        gridData : [],
        pagination : {
            pageNo : 1
        },
        bdNo : 0
    },
    mutations: {
        setGridData(state, payload) {
            state.gridData.push(payload)
        },
        setBdNo(state, payload) {
            state.bdNo = payload
        },
        clearGridData(state) {
            state.gridData.length = 0
        },
        setPagination(state, payload) {
            state.pagination = payload
        },
        clearPagination(state) {
            state.pagination = {
                pageNo : 1
            }
        }
    },
    actions: {
        async deleteUser({ state,commit }, payload){
            let rtn = {}
            rtn = await deleteUser(payload)
            console.log(rtn)
        },
        async updateUserLevel({ state,commit }, payload){
            let rtn = {}
            rtn = await updateUserLevel(payload)
            console.log(rtn)
        },
        async fetchAdminApply ({state, commit}, payload) {
            commit('clearGridData')

            let rtn = {}
            rtn = await getApplyList(payload)

            for (const vo of rtn?.applyList) {
                let dataObj = {}
                for (const info of adminApply.cell) {
                    dataObj[`${info.key}`]=vo[`${info.key}`]
                }
                commit('setGridData', dataObj)
            }
            commit('setPagination', rtn?.pagingBean)
        },
        async cancelApply({ state,commit }, payload){
            let rtn = {}
            rtn = await cancelApply(payload)
            console.log(rtn)
            alert(rtn.resultMsg)
        },
    }
}