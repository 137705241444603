import admin from './admin'
import detail from './detail'
import grid from './grid'
import member from './member'
import temp from './temp'
import treeMenu from './treeMenu'
export default {
    admin,
    detail,
    grid,
    member,
    temp,
    treeMenu,
}