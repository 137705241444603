<template>
  <li>
    <!-- <a href="#" @click="menuClick">
        {{ menu }}
    </a> -->
    <router-link :to="routeUrl" @click="menuClick">{{ menu }}</router-link>
  </li>
</template>

<script>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  props: {
    index : Number,
    menu : String, 
    routeUrl : String
  },
  setup(props) {

    const store = useStore()
    const router = useRouter()
    const tree = store.state.treeMenu.tree

    const menuClick = () => {

      const pathName = props.routeUrl
      store.commit('temp/clearSearchParam')
      store.commit('temp/setIsMain', true)
      store.commit('treeMenu/setClear')
      store.commit('treeMenu/setSelected', props.index)
      store.commit("treeMenu/setCurrentKey", pathName)
      store.commit("treeMenu/setParentIndex", 0)
      store.commit("treeMenu/setCurrentIndex", 1)
      // console.log(tree[props.index].name)
      store.commit("treeMenu/setCurrentKeys", tree[props.index].name)

      router.push({
          name: pathName
      })
      
    }

    return {
      menuClick
    }

  }
}
</script>

<style scoed>

</style>