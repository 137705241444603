<template>
	
	<div class="subtop">
		<div class="subtop-cont">
			<div class="subtop-title title-2">
				<p>관리자 페이지</p>
				<span></span>
			</div>
		</div>
		<img class="subtop-img" src="@/assets/images/img/subtop-1.png">
	</div>
	<div class="sub-menu">
		<ul class="float-wrap">
			<li class="on"><router-link to="/admin-member">회원관리</router-link></li>
			<li><router-link to="/admin-apply">자격신청 관리</router-link></li>
			<li><router-link to="/admin-contest">대회신청 관리</router-link></li>
		</ul>
	</div>
	<!--서브내용-->
	<div class="sub-wrap">
		<div class="sub-cont">
			<p class="title-sub">회원관리</p>

			<BoardTop :gridInfo="adminMemberJson" @getGridData="getGridData" v-model="searchText"/>

			<Grid :gridInfo="adminMemberJson" :dataList="adminMemberList" @deleteMember="deleteMember" />

			<Pagination v-if="adminMemberJson.pagination" 
						:gridInfo="adminMemberJson" 
						:editorType="''"
						:boardType="''" 
						:buttonYn="false" 
						@getGridData="getGridData" />

		</div>
	</div>
</template>

<script setup>
import BoardTop from '@/components/common/grid/boardtop'
import Grid from '@/components/admin/member/grid'
import Pagination from '@/components/admin/member/pagination'
import adminMemberJson from '@/models/gridInfo/adminMember.json'
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'

	const store = useStore()
		
	const searchText = ref('')

	const adminMemberList = computed(() => {
		return store.state.grid.gridData
	})

    const isAdmin = store.state.temp.isAdmin

	const searchParam = computed(() => {
		return store.state.temp.searchParam 
	})

	const getGridData = async () => {
		let param = {pageNo : searchParam.value.pageNo, search : searchText.value}
		await store.dispatch('grid/fetchAdminMember', param)
	}
	
	const deleteMember = async (data) => {
		
		if(confirm("탈퇴하면 사용자 데이터가 영구적으로 삭제 됩니다. 삭제 하시겠습니까?")) {
			let params = {
				userId : data.userId
			};
            await store.dispatch('admin/deleteUser', params)
            let param = {pageNo : searchParam.value.pageNo, search : searchText.value}
            await store.dispatch('grid/fetchAdminMember', param)
		}
		
	};

	onMounted(async () => {
		store.commit('temp/clearSearchParam')
		if(!isAdmin){
            alert("해당 페이지 접근권한이 없습니다.")
			history.back(-1 )
        }
		getGridData()
	})

</script>

<style></style>