<template>
    <!-- 댓글 -->
    <div class="reply">
        <div class="reply-box" v-for="item in replyList" :key="item">
            <div class="reply-account">
                <div class="reply-account-img">
                    <img src="@/assets/images/img/profile-default.png">
                </div>
                <p>{{ item.replyWriterName }}</p>
            </div>
            <p>{{ item.replyCont }}</p>
            <span>{{ item.regDt }}</span>
            
            <div class="reply-input" v-if="item.secretYn == 'Y'">
                <textarea v-model="item.replyPass" placeholder="댓글을 입력해주세요"></textarea>
                <button type="button" class="btn btn-primary btn-sm" @click="upd(item)">댓글 수정</button>
                <button type="button" class="btn btn-primary btn-sm" @click="cancelReplyForm(item)">취소</button>
            </div>
            &nbsp;
            <span style="cursor: pointer;"
                    v-if="item.replyWriterId == userInfo?.userId"
                    @click="updateReplyForm(item)">수정</span> &nbsp;&nbsp;
            <span style="cursor: pointer;" v-if="item.replyWriterId == userInfo?.userId || userInfo?.userLevel > 2" 
                    @click="del(item.replyNo)">삭제</span>

        </div>
        <div class="reply-input">
            <textarea :value="modelValue" 
                        @input="$emit('update:modelValue', $event.target.value)"
                        placeholder="댓글을 입력해주세요"></textarea>
            <button type="button" class="btn btn-primary btn-sm" @click="reg">댓글 등록</button>
        </div>
    </div>
</template>
<script setup>

    const props = defineProps({
        modelValue : String,
        userInfo : Object,
        replyList: Array,
    });
    
    const emit = defineEmits(['regReply', 'updateReply' , 'deleteReply', 'update:modelValue']);

    const updateReplyForm = async (item) => {
        item.secretYn = 'Y'
        item.replyPass = item.replyCont
    }

    const cancelReplyForm = async (item) => {
        item.secretYn = 'N'
        item.replyPass = null
    }

	const reg = () => {
        if(props.userInfo.userId !== '') {
            emit("regReply")
        } else {
            alert("로그인 후 이용해주세요.")
        }
	}
    
	const upd = async (item) => {
        if(props.userInfo.userId !== '') {
            emit("updateReply", item)
        } else {
            alert("로그인 후 이용해주세요.")
        }
	}
    
	const del = async (replyNo) => {
        if(props.userInfo.userId !== '') {
            emit("deleteReply", replyNo)
        } else {
            alert("로그인 후 이용해주세요.")
        }
	}

</script>
<style>
    
</style>