<template>

    <!--서브내용-->
    <div class="sub-wrap sub-wrap-grey">
        <div class="sub-cont">
            <div class="title-2">
                <p>회원가입</p>
                <span>회원가입약관 및 개인정보 수집 및 이용의 내용에 동의하셔야 회원가입 하실 수 있습니다.</span>
            </div>

            <div class="policy">
                <p class="policy-title">이용약관</p>
                <div class="policy-box">
                    <p>
                        제 1 장 : 총칙<br>
                        제 2 장 : 서비스 이용계약<br>
                        제 3 장 : 계약당사자의 의무<br>
                        제 4 장 : 서비스 이용<br>
                        제 5 장 : 계약해지 및 이용제한<br>
                        제 6 장 : 기타<br><br><br>
                        제1장 총 칙<br><br>
                        제1조(목적)<br>
                        이 약관은 대한서핑협회(이하 “KSA”라 한다)에서 제공하는 모든 서비스(이하 “서비스”라 한다)의 이용조건 및 절차에 관한 사항을 규정함을 목적으로 합니다.<br><br>
                        제 1 장 : 총칙<br>
                        제 2 장 : 서비스 이용계약<br>
                        제 3 장 : 계약당사자의 의무<br>
                        제 4 장 : 서비스 이용<br>
                        제 5 장 : 계약해지 및 이용제한<br>
                        제 6 장 : 기타<br><br><br>
                        제1장 총 칙<br><br>
                        제1조(목적)<br>
                        이 약관은 대한서핑협회(이하 “KSA”라 한다)에서 제공하는 모든 서비스(이하 “서비스”라 한다)의 이용조건 및 절차에 관한 사항을 규정함을 목적으로 합니다.<br>
                    </p>
                </div>
                <div class="radio-wrap">
                    <div class="radio-boxes">
                        <div class="radio-box">
                            <input type="radio" name="policy" id="policy_y" @click="policy1('Y')">
                            <label for="policy_y">동의합니다</label>
                        </div>
                        <div class="radio-box">
                            <input type="radio" name="policy" id="policy_n" @click="policy1('N')">
                            <label for="policy_n">동의하지 않습니다</label>
                        </div>
                    </div>
                </div>
                <p class="policy-title">개인정보수집이용안내</p>
                <div class="policy-box">
                    <p>
                        ‘대한서핑협회’는 (이하 ‘KSA’는) 회원님의 개인정보를 중요시하며, “정보통신망 이용촉진 및 정보보호”에 관한 법률을 준수하고 있습니다.<br>
                        KSA는 개인정보취급방침을 통하여 회원님께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지
                        알려드립니다.<br><br>
                        KSA는 개인정보취급방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.<br>
                        ο 본 방침은 : 2014 년 05 월 01 일 부터 시행됩니다.<br><br>
                        ■ 수집하는 개인정보 항목<br>
                        KSA는 회원가입, 상담, 서비스 신청 등등을 위해 아래와 같은 개인정보를 수집하고 있습니다.<br><br>
                        ο 수집항목 : 이름 , 성별, 생년월일 , 로그인ID , 비밀번호 , 별명, 거주지역 , 휴대전화번호 , 이메일 , 서비스 이용기록 , 접속 로그 , 쿠키 , 접속 IP
                        정보<br>
                        ο 개인정보 수집방법 : 홈페이지(회원가입) , 생성정보 수집 툴을 통한 수집<br><br>

                        ‘대한서핑협회’는 (이하 ‘KSA’는) 회원님의 개인정보를 중요시하며, “정보통신망 이용촉진 및 정보보호”에 관한 법률을 준수하고 있습니다.<br>
                        KSA는 개인정보취급방침을 통하여 회원님께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지
                        알려드립니다.<br><br>
                        KSA는 개인정보취급방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.<br>
                        ο 본 방침은 : 2014 년 05 월 01 일 부터 시행됩니다.<br><br>
                        ■ 수집하는 개인정보 항목<br>
                        KSA는 회원가입, 상담, 서비스 신청 등등을 위해 아래와 같은 개인정보를 수집하고 있습니다.<br><br>
                        ο 수집항목 : 이름 , 성별, 생년월일 , 로그인ID , 비밀번호 , 별명, 거주지역 , 휴대전화번호 , 이메일 , 서비스 이용기록 , 접속 로그 , 쿠키 , 접속 IP
                        정보<br>
                        ο 개인정보 수집방법 : 홈페이지(회원가입) , 생성정보 수집 툴을 통한 수집<br>
                    </p>
                </div>
                <div class="radio-wrap">
                    <div class="radio-boxes">
                        <div class="radio-box">
                            <input type="radio" name="info_collect" id="info_collect_y" @click="policy2('Y')">
                            <label for="info_collect_y">동의합니다</label>
                        </div>
                        <div class="radio-box">
                            <input type="radio" name="info_collect" id="info_collect_n" @click="policy2('N')">
                            <label for="info_collect_n">동의하지 않습니다</label>
                        </div>
                    </div>
                </div>

                <button type="button" class="btn btm-btn" @click="joinClick">회원 가입</button>

            </div>
        </div>
    </div>
</template>

<script>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
export default {
    setup() {
        const router = useRouter()
        const store = useStore();
        const policy1 = (v) => {
            store.commit('member/setPolicy1',v)
        }
        const policy2 = (v) => {
            store.commit('member/setPolicy2',v)
        }
        const joinClick = () => {
            if(store.state.member.policy1 == 'Y' && store.state.member.policy2 == 'Y'){
                store.commit('member/clearPolicy1')
                store.commit('member/clearPolicy2')
                router.push('/member-join')
            } else {
                alert('약관에 동의해주세요.')
            }
        }
        return {
            policy1,
            policy2,
            joinClick
        }
    }
}
</script>

<style></style>