import affiliate from       '@/pages/player/affiliate'
import info from       '@/pages/player/info'

export default [
    {
        path: '/player-affiliate', 
        name: 'affiliate',
        component: affiliate,
    },
    {
        path: '/player-info',
        name: 'player',
        component: info
    }
]
