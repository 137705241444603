<template>
    <div class="subtop">
        <div class="subtop-cont">
            <div class="subtop-title title-2">
                <p>ABOUT KSA</p>
                <span>대한서핑협회를 소개 합니다.</span>
            </div>
        </div>
        <img class="subtop-img" src="@/assets/images/img/subtop-1.png">
    </div>
    <!--서뷰메뉴-->
    <div class="sub-menu" style="margin-top: 15px;">
        <ul class="float-wrap">
			<!-- <li class="on"><router-link to="/about-greeting">인사말</router-link></li>
			<li><router-link to="/about-ksa">협회소개</router-link></li>
			<li><router-link to="/about-organization">조직도</router-link></li>
			<li><router-link to="/about-rules">시/도협회</router-link></li> -->

			<div class="tab-container tab-box">
				<div class="tab tab-4">
					<button type="button" @click="setTab(1)" :class="''">인사말</button>
					<button type="button" @click="setTab(2)" :class="''">협회소개</button>
					<button type="button" @click="setTab(3)" :class="''">조직도</button>
					<button type="button" @click="setTab(4)" :class="'on'">시/도협회</button>
				</div>
			</div>
		</ul>
	</div>
    
    <!--서브내용-->
    <div class="sub-wrap sub-wrap-grey">
        <div class="sub-cont">

            <!--시/도협회-->
            <div class="board">
                <div class="assication-map">
                    <img src="@/assets/images/img/map1.jpeg" alt="">
                </div>
				<!--
                <div class="assication-map">
                    <img src="@/assets/images/img/membership_03.jpg" alt="">
                </div>
				-->
            </div>

        </div>
    </div>

</template>

<script setup>
	import { ref } from 'vue'
	import { useStore } from 'vuex'
	import { useRouter } from 'vue-router'

	const store = useStore()
	const router = useRouter()

	let { tree } = store.state.treeMenu
	// console.log(tree)
	
	//탭
	const activeTabId = ref(1)

	const setTab = (tabId) => {
		activeTabId.value = tabId
		if(tabId == 1) {
			router.push({ path : '/about-greeting' })
		}	
		if (tabId == 2) {
			router.push({ path: '/about-ksa' })
		}
		if (tabId == 3) {
			router.push({ path: '/about-organization' })
		}
		if (tabId == 4) {
			router.push({ path: '/about-rules' })
		}
	} 

</script>

<style>

</style>