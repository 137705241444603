<template>
	<div class="subtop">
		<div class="subtop-cont">
			<div class="subtop-title title-2">
				<p>대회정보</p>
				<span>최신 대회정보를 확인 하세요.</span>
			</div>
		</div>
		<img class="subtop-img" src="@/assets/images/img/subtop-1.png">
	</div>
    <!--
    <div class="sub-menu">
		<ul class="float-wrap">
			<li class="on"><router-link to="/about-greeting">시/도선수</router-link></li>
			<li><router-link to="/about-ksa">지도자</router-link></li>
			<li><router-link to="/about-organization">심판</router-link></li>
		</ul>
	</div>
    -->
	
    <div class="sub-menu" style="margin-top: 15px;">
        <ul class="float-wrap" style="width: 50%;">
            <!-- <li><router-link to="/match-rule">KSA 운영 및 룰</router-link></li>
			<li class="on"><router-link to="/match-list">대회소식</router-link></li> -->
			<div class="tab-container tab-box">
                <div class="tab tab-3">
					<button type="button" @click="setTab(2)" :class="'on'">대회소식</button>
					<button type="button" @click="setTab(3)" :class="''">KSA랭킹</button>
                    <button type="button" @click="setTab(1)" :class="''">대회운영</button>
                </div>
			</div>
		</ul>
    </div>

	<!--서브내용-->
	<div class="sub-wrap">
		<div class="sub-cont">
			<p class="title-sub">대회소식</p>

			<BoardTop :gridInfo="matchJson" @getGridData="getGridData" v-model="searchText"/>

			<Grid :gridInfo="matchJson" :dataList="matchList" />

			<Pagination v-if="matchJson.pagination" :gridInfo="matchJson" :editorType="'event'" :boardType="matchJson?.boardType" @getGridData="getGridData" />

		</div>
	</div>
</template>

<script setup>
	import BoardTop from '@/components/common/grid/boardtop'
	import Grid from '@/components/common/grid/grid'
	import Pagination from '@/components/common/grid/pagination'
    import matchJson from '@/models/gridInfo/match.json'
	import { ref, computed, onMounted, onUnmounted } from 'vue'
	import { useStore } from 'vuex'
	import { useRouter } from 'vue-router'

	const store = useStore()
	const router = useRouter()
	
	const searchText = ref('')

	const matchList = computed(() => {
		return store.state.grid.gridData
	})

	const searchParam = computed(() => {
		return store.state.temp.searchParam 
	})

	const getGridData = async () => {
		let param = {pageNo : searchParam.value.pageNo, search : searchText.value}
		await store.dispatch('grid/fetchMatch', param)
	}
	
	const getMessgage = (e) => {
		if(e.origin == process.env.VUE_APP_API_URL) {
			getGridData()
		}
	}

	onMounted(async () => {
		store.commit('temp/clearSearchParam')
		getGridData()
		// 글쓰기, 수정하기 이벤트리스너 체크
		window.addEventListener('message', getMessgage)
	})

	onUnmounted(() => {
		store.commit('temp/clearSearchParam')
		// 페이지 닫힐 때 해당 리스너 삭제
		window.removeEventListener('message', getMessgage)
	})

	//Tab
    const activeTabId = ref(1)

    const setTab = (tabId) => {
        activeTabId.value = tabId
		if(tabId == 1) {
			router.push({ path : '/match-list' })
		}	
		if (tabId == 2) {
			router.push({ path: '/match-rule' })
		}
		if (tabId == 3) {
			router.push({ path: '/match-ranking' })
		}
    }  
</script>

<style></style>