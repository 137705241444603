<template>
    <!--로그인-->
    <div class="member login">
        <div class="member-bg">
            <div class="member-box">
                <div class="title-2">
                    <p>로그인</p>
                    <span>정보를 입력 하고 회원가입 하실 수 있습니다.</span>
                </div>
                <div class="member-inputs">
                
                    <!--아이디-->
                    <div class="input-box">
                        <label>아이디</label>
                        <input type="text" placeholder="아이디를 입력하세요" v-model="userId">
                    </div>
                    
                    <!--비밀번호-->
                    <div class="input-box">
                        <label>비밀번호</label>
                        <input type="password" placeholder="비밀번호를 입력하세요" v-model="password" @keyup.enter="login">
                    </div>
                    
                    <!-- <a href="#" class="member-link">아이디 / 비밀번호 찾기</a> -->
                </div>
                <div class="input-btns">
                    <button type="button" class="btn btm-btn accent" @click="login">로그인</button>
                    <router-link :to="'/member-policy'" :slot-scope="'button'">
                        <button type="button" class="btn btm-btn">회원 가입</button>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { loginAjax } from '@/api/auth'
import { delCookie, setCookie } from '@/util/util'
import router from '@/router'


const store = useStore()

const userId = ref('')
const password = ref('')
const isLogin = store.state.temp.isLogin

const login = async () => {
    if (!userId.value) {
        alert("아이디를 입력하세요")
        return
    }

    if (!password.value) {
        alert("비밀번호를 입력하세요")
        return
    }

    let obj = {
        userId : userId.value,
        password : password.value
    }

    let rtn = await loginAjax(obj)
    
    alert(rtn?.resultMsg)

    if(rtn?.result === 'ok') {
        
        delCookie('sessionId')
        setCookie('sessionId', rtn?.sessionId, 3)
        store.commit('temp/setIsLogin', true)
        store.commit('temp/setUserInfo', {key : 'userId', value: rtn?.userId})
        store.commit('temp/setUserInfo', {key : 'userName', value: rtn?.userName})
        store.commit('temp/setUserInfo', {key : 'userLevel', value: rtn?.userLevel})
        
        if(rtn?.adminYn === 'Y') {
            store.commit('temp/setIsAdmin', true)
        }

        router.push({
            name : 'Home'
        })

    }
    
}

onMounted(async () => {
    if(isLogin){
        history.back(-1 )
    }
})
</script>

<style>

</style>