<template>
	<div class="subtop">
		<div class="subtop-cont">
			<div class="subtop-title title-2">
				<p>공지 및 소식</p>
				<span>최신 뉴스와 공지사항을 확인하세요.</span>
			</div>
		</div>
		<img class="subtop-img" src="@/assets/images/img/subtop-1.png">
	</div>
    <div class="sub-menu" style="margin-top: 15px;">
        <ul class="float-wrap" style="width: 50%;">
			<!-- <li class="on"><router-link to="/notice-list">공지사항</router-link></li>
			<li><router-link to="/news-list">뉴스게시판</router-link></li>
			<li><router-link to="/notice-gallery">갤러리</router-link></li> -->

			<div class="tab-container tab-box">
                <div class="tab tab-3">
                    <button type="button" @click="setTab(1)" :class="''">공지사항</button>
                    <button type="button" @click="setTab(2)" :class="''">뉴스 게시판</button>
					<button type="button" @click="setTab(3)" :class="'on'">갤러리</button>
                </div>
			</div>
		</ul>
	</div>

	<!--서브내용-->
	<div class="sub-wrap">
		<div class="sub-cont">
			<p class="title-sub">갤러리</p>

			<!--갤러리-->
			<div class="gallery gallery-sub">
				<div class="gallery-box" 
				v-for="(item, index) in galleryList" :key="item"
				:class="'box-' + ((index % 7) + 1)" 
				@click="openModal(item)"
				>
					<div class="gallery-desc">
						<span>{{item?.bdTitle}}</span>
					</div>
					<img :src="apiBaseUrl + item?.bdThumbImg1">
				</div>
			</div>
			<!--갤러리 팝업-->
      		<Modal :photo="selectedPhoto" :dataList="galleryList" :visible="modalVisible" 
					@close="closeModal" @chgPhoto="chgPhoto" @updateGallery="updateGallery" @deleteGallery="deleteGallery" />
			<Pagination v-if="galleryJson.pagination" :gridInfo="galleryJson" :editorType="'gallery'" :boardType="galleryJson?.boardType" @getGridData="getGalleryData" />
		</div>
	</div>
</template>

<script setup>
	import galleryJson from '@/models/gridInfo/gallery.json'
	import Pagination from '@/components/common/grid/pagination'
	import Modal from '@/components/common/modal/modal'
	import { useStore } from 'vuex'
    import { useRoute } from 'vue-router'
	import { useRouter } from 'vue-router'
	import { computed, onMounted, onUnmounted, ref } from 'vue'
	import { getCookie } from '@/util/util'
import { del } from '@/api/axios'

	const store = useStore()
	const route = useRoute()
	const router = useRouter()
	const cookie = getCookie(document.cookie)
	
    const apiBaseUrl = process.env.VUE_APP_API_URL;

	const galleryList = computed(() => {
		return store.state.grid.gridData
	})

	const searchParam = computed(() => {
		return store.state.temp.searchParam 
	})

	const getGalleryData = async () => {
		let param = {pageNo : searchParam.value.pageNo, search : ''}
		await store.dispatch('grid/fetchGallery', param)
	}

	const updateGallery = (bdNo) => {
		if(cookie.sessionId == undefined){
			alert("로그인 후 이용 가능합니다.");
		} else {
			const url = process.env.VUE_APP_API_URL + "/gallery/update?bdTypeCd=12&bdNo=" + bdNo + '&sessionId=' + cookie.sessionId
			const popup = window.open( url
				, '_blank'
				, 'chrome=1 ,centerscreen=1, innerWidth=1000, innerHeight=720'
				, false)
		}
	}

	const deleteGallery = async (bdNo) => {
		if(cookie.sessionId == undefined){
			alert("로그인 후 이용 가능합니다.");
		} else if(confirm("정말 삭제하시겠습니까?")) {
			let rtn = await del('/board/delete', {bdNo : bdNo, bdWriterId : store.state.temp.userInfo.userId})
			if(rtn.data.result === 'ok') {
				getGalleryData()
				closeModal()
			} else {
				alert(rtn.data.resultMsg)
				closeModal()
			}
		}
	}
	const modalVisible = ref(false);
	const selectedPhoto = ref(null);

	const openModal = async (photo) => {
        selectedPhoto.value = photo;
        modalVisible.value = true;
	}
	
	const closeModal = () => {
        modalVisible.value = false;
    }
	
	const chgPhoto = (photo) => {
        selectedPhoto.value = photo;
    }

	const getMessgage = (e) => {
		if(e.origin == process.env.VUE_APP_API_URL) {
			getGalleryData()
			closeModal()
		}
	}

	onMounted(async() => {
		store.commit('temp/clearSearchParam')
		getGalleryData()
		// 글쓰기, 수정하기 이벤트리스너 체크
		window.addEventListener('message', getMessgage)
	})

	onUnmounted(() => {
		store.commit('temp/clearSearchParam')
		// 페이지 닫힐 때 해당 리스너 삭제
		window.removeEventListener('message', getMessgage)
	})

	//탭
	const activeTabId = ref(1)

	const setTab = (tabId) => {
		activeTabId.value = tabId
		if(tabId == 1) {
			router.push({ path : '/notice-list' })
		}	
		if (tabId == 2) {
			router.push({ path: '/news-list' })
		}
		if (tabId == 3) {
			router.push({ path: '/notice-gallery' })
		}
	}
</script>

<style></style>