<template>

	<div class="subtop">
		<div class="subtop-cont">
			<div class="subtop-title title-2">
				<p>자격증</p>
				<span>대한서핑협회의 자격증 정보를 확인할 수 있습니다.</span>
			</div>
		</div>
		<img class="subtop-img" src="@/assets/images/img/subtop-1.png">
	</div>

    <div class="sub-menu" style="margin-top: 15px;">
        <ul class="float-wrap" style="width: 50%;">
			<!--
			<li><router-link to="/development-coaching">Coaching and Intruction</router-link></li>
			<li><router-link to="/development-rescue">KSA surf rescue and CPR Test</router-link></li>
			-->
			
			<!-- <li><router-link to="/development-level">자격증 안내</router-link></li>
			<li class="on"><router-link to="/development-list">자격신청</router-link></li> -->
			<div class="tab-container tab-box">
                <div class="tab tab-2">
                    <button type="button" @click="setTab(1)" :class="''">자격신청</button>
                    <button type="button" @click="setTab(2)" :class="'on'">자격증 안내</button>
                </div>
			</div>
		</ul>
	</div>

	<!--서브내용-->
	<div class="sub-wrap sub-wrap-grey">
		<div class="sub-cont">
			<!--관련규정-->
			<div class="tab-container tab-index">
				<div class="tab">
					<button type="button" :class="{ 'on': tabs === 'leader' }" @click="tabs = 'leader'">서핑 지도자</button>
					<button type="button" :class="{ 'on': tabs === 'referee' }" @click="tabs = 'referee'">서핑 심판</button>
					<button type="button" :class="{ 'on': tabs === 'supLeader' }" @click="tabs = 'supLeader'">SUP 지도자</button>
					<button type="button" :class="{ 'on': tabs === 'rescue' }" @click="tabs = 'rescue'">서프 레스큐</button>
				</div>
				<div class="tab-contents">

					<!--자격의 종류-->
					<div class="tab-cont" :class="{ 'active': tabs === 'leader' }">
						<div class="desc">
							<img src="@/assets/images/img/development_img01.jpg" alt="">
						</div>
					</div>

					<!--테스트 방법-->
					<div class="tab-cont" :class="{ 'active': tabs === 'referee' }">
						<div class="desc">
							<img src="@/assets/images/img/development_img02.jpg" alt="">
						</div>
					</div>

					<!--SUP 지도자-->
					<div class="tab-cont" :class="{ 'active': tabs === 'supLeader' }">
						<div class="desc">
							<img src="@/assets/images/img/development_img03.jpg" alt="">
						</div>
					</div>

					<!--서프 레스큐-->
					<div class="tab-cont" :class="{ 'active': tabs === 'rescue' }">
						<div class="desc">
							<img src="@/assets/images/img/development_img04.jpg" alt="">
						</div>
						<!--
						<div class="tab-cont-info">
							<h3>KSA Surf rescue</h3>
							<p>대한서핑협회에서는 클럽 강사 인증 레벨인 KSA Level3 이상 취득자를 위한 Surf rescue 과정을 적용 교육하며, Surf rescue
								과정은
								ISA의 인증방식에 따라 대한서핑협회의 헤드 인스트럭터가 교육
								테스트하게 됩니다.
								교육시간 및 교육 금액은 추후 공지하게 되며 자격은 ISA(국제서핑협회)와 KSA(대한서핑협회)가 보장하는 프로그램입니다.
								대한서핑협회에서는 클럽 강사 인증 레벨인 KSA Level3 이상 취득자를 위한 Surf rescue 과정을 적용 교육하며, Surf
								rescue
								과정은 ISA의 인증방식에 따라 대한서핑협회의 헤드 인스트럭터가 교육
								테스트하게 됩니다.
								교육시간 및 교육 금액은 추후 공지하게 되며 자격은 ISA(국제서핑협회)와 KSA(대한서핑협회)가 보장하는 프로그램입니다.
							</p>
						</div>
						<div class="tab-cont-info">
							<h3>CPR Test</h3>
							<p>대한서핑협회에서는 클럽 강사 인증 레벨인 KSA Level3 이상 취득자를 위한 대한적십자사의 심폐소생술과정을 필수 과정으로 채택하여 실행하고
								있습니다. 이에 KSA Level 3을 취득하실 분께서는 필히 자격을
								이수 하시기 바라며 대한서핑협회에서는 대한적십자사의 심폐소생술과정의 스케줄 외 대한서핑협회에서 개별 진행 함으로 스케줄을 항시 체크바랍니다.
							</p>
							<p>
								심장마비는 암(癌) 등 기타 질병과 달리 산소 부족으로 뇌 손상이 오는 5분 이내에 기초적인 심폐소생술과 전기쇼크 치료를 받으면 대부분 회복될
								수 있습니다. 하지만 우리나라에서는 가족, 목격자 등의 교육과 인식
								부족으로 현장에서 심폐소생술이 이뤄지는 경우가 3 ~ 4%에 머물러 있는 실정입니다. 이 때문에 살 수 있는 환자들이 허망한 죽음을 맞거나,
								살더라도 뇌 손상 등 심각한 후유증에 시달리게 됩니다. 심폐 소생술은
								일상생활에서 심장 쇼크가 발생하였을 때 즉각적이고 올바를 처치를 행할 수 있도록 하는 지식과 기능을 말합니다. 심폐소생술과정은 응급처치법의
								원리, 심장발잦의 원인과 증상, 성인, 어린이, 영아의 인공호흡법과
								심폐소생술, 자동제세동기(AED)의 사용법, 기도폐쇄 처치법을 배우는 과정으로서 고등학교 1년 이상이면 누구나 강습에 참가할 수 있으며,
								강습시간은 6시간 입니다.
							</p>
							<em>강습시간 참가대상 교육내용 교제 정원 수료증 유효기간
								6시간 고등학교 1년 이상 - 응급처치법의 원리</em>
							<ul>
								<li>심폐소생술</li>
								<li>자동제세동기의 사용</li>
								<li>기도폐쇄</li>
								<li>심폐소생술 10~20명 1년</li>
							</ul>
						</div>
						-->

					</div>

				</div>
			</div>
		</div>
	</div>

</template>

<!-- <script setup>
	import { useStore } from 'vuex'

	const store = useStore()
	
	const apiBaseUrl = process.env.VUE_APP_API_URL

</script> -->

<script setup>
	import { ref } from 'vue'
	import { useRouter } from 'vue-router'
	
	const tabs = ref('leader')
	const router = useRouter()

	//탭
	const activeTabId = ref(2)

	const setTab = (tabId) => {
		activeTabId.value = tabId
		if(tabId == 1) {
			router.push({ path : '/development-list' })
		}	
		if (tabId == 2) {
			router.push({ path: '/development-level' })
		}
	}  
	
</script>

<style></style>