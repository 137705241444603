<template>
    <div class="container">
        <div class="conents"
            :class="!isMain ? 'main' : ''"
        >
            <router-view/>
        </div>
    </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'

    const store = useStore();
    const isMain = computed(() => {
        return store.state.temp.isMain
    })
        
</script>

<style scoped>

</style>