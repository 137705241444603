import list from       '@/pages/match/list'
import rule from       '@/pages/match/rule'
import ranking from  '@/pages/match/ranking'
import view from       '@/pages/match/view'


export default [
  {
    path: "/match-rule",
    name: "rule",
    component: rule,
  },
  {
    path: "/match-list",
    name: "match-list",
    component: list,
  },
  {
    path: "/match-ranking",
    name: "ranking",
    component: ranking,
  },
  {
    path: "/match-view",
    name: "match-view",
    component: view,
  },
];
