<template>
	<div class="subtop">
		<div class="subtop-cont">
			<div class="subtop-title title-2">
				<p>공지 및 소식</p>
				<span>최신 뉴스와 공지사항을 확인하세요.</span>
			</div>
		</div>
		<img class="subtop-img" src="@/assets/images/img/subtop-1.png">
	</div>
	<div class="sub-menu" style="margin-top: 15px;">
        <ul class="float-wrap" style="width: 50%;">
			<!-- <li class="on"><router-link to="/notice-list">공지사항</router-link></li>
			<li><router-link to="/news-list">뉴스게시판</router-link></li>
			<li><router-link to="/notice-gallery">갤러리</router-link></li> -->

			<div class="tab-container tab-box">
                <div class="tab tab-3">
                    <button type="button" @click="setTab(1)" :class="'on'">공지사항</button>
                    <button type="button" @click="setTab(2)" :class="''">뉴스 게시판</button>
					<button type="button" @click="setTab(3)" :class="''">갤러리</button>
                </div>
			</div>
		</ul>
	</div>

	<!--서브내용-->
	<div class="sub-wrap">
		<div class="sub-cont">
			<p class="title-sub">공지사항</p>

			<BoardTop :gridInfo="noticeJson" @getGridData="getGridData" v-model="searchText"/>

			<Grid :gridInfo="noticeJson" :dataList="noticeList" />

			<Pagination v-if="noticeJson.pagination" :gridInfo="noticeJson" :editorType="'notice'" :boardType="noticeJson?.boardType" @getGridData="getGridData" />

		</div>
	</div>
</template>

<script setup>
	import BoardTop from '@/components/common/grid/boardtop'
	import Grid from '@/components/common/grid/grid'
	import Pagination from '@/components/common/grid/pagination'
	import noticeJson from '@/models/gridInfo/notice.json'
	import { ref, computed, onMounted, onUnmounted } from 'vue'
	import { useStore } from 'vuex'
	import { useRouter } from 'vue-router'

	const store = useStore()
	const router = useRouter()
	
	const searchText = ref('')

	const noticeList = computed(() => {
		return store.state.grid.gridData
	})

	const searchParam = computed(() => {
		return store.state.temp.searchParam 
	})

	const getGridData = async () => {
		let param = {pageNo : searchParam.value.pageNo, search : searchText.value}
		await store.dispatch('grid/fetchNotice', param)
	}
	
	const getMessgage = (e) => {
		if(e.origin == process.env.VUE_APP_API_URL) {
			getGridData()
		}
	}

	onMounted(async () => {
		store.commit('temp/clearSearchParam')
		getGridData()
		// 글쓰기, 수정하기 이벤트리스너 체크
		window.addEventListener('message', getMessgage)
	})

	onUnmounted(() => {
		store.commit('temp/clearSearchParam')
		// 페이지 닫힐 때 해당 리스너 삭제
		window.removeEventListener('message', getMessgage)
	})

	//탭
	const activeTabId = ref(1)

	const setTab = (tabId) => {
		activeTabId.value = tabId
		if(tabId == 1) {
			router.push({ path : '/notice-list' })
		}	
		if (tabId == 2) {
			router.push({ path: '/news-list' })
		}
		if (tabId == 3) {
			router.push({ path: '/notice-gallery' })
		}
	}
</script>

<style></style>