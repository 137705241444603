<template>
	<div class="subtop">
		<div class="subtop-cont">
			<div class="subtop-title title-2">
				<p>공지 및 소식</p>
				<span>최신 뉴스와 공지사항을 확인하세요.</span>
			</div>
		</div>
		<img class="subtop-img" src="@/assets/images/img/subtop-1.png">
	</div>
    <div class="sub-menu">
		<ul class="float-wrap">
			<li><router-link to="/notice-list">공지사항</router-link></li>
			<li class="on"><router-link to="/news-list">질문과 답변</router-link></li>
			<li><router-link to="/notice-gallery">갤러리</router-link></li>
		</ul>
	</div>

	<!--서브내용-->
	<div class="sub-wrap">
		<div class="sub-cont">
			<div class="title-2">
				<p>질문과답변</p>
				<span>지역별 가맹샵 또는 가맹샵명 검색으로 찾아보세요.</span>
			</div>

			<!--질문과답변-->
			<div class="qna">
				<div class="qna-box">
					<button type="button">ISA 자격 테스트 하기 위해 뭘 중비해야 됩니까? ISA 자격 테스트 하기 위해 뭘 중비해야 됩니까? ISA 자격 테스트 하기 위해 뭘 중비해야
						됩니까?</button>
					<p>ISA Coaching & Instructing 테스트에는 ISA의 Coaching& instructing 전문회된 ISA 강사가 한국으로 파견되어 테스트를 실시하게 된다. 교육은
						총2일 과정으로 진행되며 심판과정 추가 시 1일 추가 된다.<br>ISA Level 1 Instruction course 자격을 테스트 하기 위해선 아래 와 같은 조건을 필요로
						한다.</p>
				</div>
				<div class="qna-box">
					<button type="button">ISA 자격 테스트 하기 위해 뭘 중비해야 됩니까?</button>
					<p>ISA Coaching & Instructing 테스트에는 ISA의 Coaching& instructing 전문회된 ISA 강사가 한국으로 파견되어 테스트를 실시하게 된다. 교육은
						총2일 과정으로 진행되며 심판과정 추가 시 1일 추가 된다.<br>ISA Level 1 Instruction course 자격을 테스트 하기 위해선 아래 와 같은 조건을 필요로
						한다.</p>
				</div>
				<div class="qna-box">
					<button type="button">ISA 자격 테스트 하기 위해 뭘 중비해야 됩니까?</button>
					<p>ISA Coaching & Instructing 테스트에는 ISA의 Coaching& instructing 전문회된 ISA 강사가 한국으로 파견되어 테스트를 실시하게 된다. 교육은
						총2일 과정으로 진행되며 심판과정 추가 시 1일 추가 된다.<br>ISA Level 1 Instruction course 자격을 테스트 하기 위해선 아래 와 같은 조건을 필요로
						한다.</p>
				</div>
				<div class="qna-box">
					<button type="button">ISA 자격 테스트 하기 위해 뭘 중비해야 됩니까?</button>
					<p>ISA Coaching & Instructing 테스트에는 ISA의 Coaching& instructing 전문회된 ISA 강사가 한국으로 파견되어 테스트를 실시하게 된다. 교육은
						총2일 과정으로 진행되며 심판과정 추가 시 1일 추가 된다.<br>ISA Level 1 Instruction course 자격을 테스트 하기 위해선 아래 와 같은 조건을 필요로
						한다.</p>
				</div>
				<div class="qna-box">
					<button type="button">ISA 자격 테스트 하기 위해 뭘 중비해야 됩니까?</button>
					<p>ISA Coaching & Instructing 테스트에는 ISA의 Coaching& instructing 전문회된 ISA 강사가 한국으로 파견되어 테스트를 실시하게 된다. 교육은
						총2일 과정으로 진행되며 심판과정 추가 시 1일 추가 된다.<br>ISA Level 1 Instruction course 자격을 테스트 하기 위해선 아래 와 같은 조건을 필요로
						한다.</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {

}
</script>

<style></style>