<template>
	
	<div class="subtop">
		<div class="subtop-cont">
			<div class="subtop-title title-2">
				<p>관리자 페이지</p>
				<span></span>
			</div>
		</div>
		<img class="subtop-img" src="@/assets/images/img/subtop-1.png">
	</div>
	<div class="sub-menu">
		<ul class="float-wrap">
			<li><router-link to="/admin-member">회원관리</router-link></li>
			<li class="on"><router-link to="/admin-apply">자격신청 관리</router-link></li>
			<li><router-link to="/admin-contest">대회신청 관리</router-link></li>
		</ul>
	</div>
	<!--서브내용-->
	<div class="sub-wrap">
		<div class="sub-cont">
			<p class="title-sub">자격신청 관리</p>

			<Grid :gridInfo="adminDevelopmentJson" :dataList="developmentList" />

			<GridPagination v-if="adminDevelopmentJson.pagination" 
						:gridInfo="adminDevelopmentJson" 
						:buttonYn="false" 
						@getGridData="getGridData" />

		</div>
		<div class="sub-cont" style="padding-top: 80px;" v-if="isApply">
			<p>자격신청 관리</p>

			<BoardTop :gridInfo="adminApplyJson" @getApplyList="getApplyList" v-model="searchText"/>

			<ApplyGrid :gridInfo="adminApplyJson" :dataList="applyList" />

			<Pagination v-if="adminApplyJson.pagination" 
						:gridInfo="adminApplyJson" 
						@getApplyList="getApplyList" />

		</div>
	</div>
</template>

<script setup>
import BoardTop from '@/components/admin/apply/boardtop'
import Grid from '@/components/admin/apply/grid'
import ApplyGrid from '@/components/admin/apply/applyGrid.vue'
import GridPagination from '@/components/common/grid/pagination'
import Pagination from '@/components/admin/apply/pagination'
import adminDevelopmentJson from '@/models/gridInfo/adminDevelopment.json'

import adminApplyJson from '@/models/gridInfo/adminApply.json'
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'

	const store = useStore()
		
	const searchText = ref('')

	const developmentList = computed(() => {
		return store.state.grid.gridData
	})

	const applyList = computed(() => {
		return store.state.admin.gridData
	})

    const isAdmin = store.state.temp.isAdmin

	const searchParam = computed(() => {
		return store.state.temp.searchParam 
	})

	const searchParamForApply = computed(() => {
		return store.state.temp.searchParamForApply 
	})

	const isApply = computed(() => {
		return store.state.temp.isApply 
	})

	const getGridData = async () => {
		let param = {pageNo : searchParam.value.pageNo}
		await store.dispatch('grid/fetchAdminDevelop', param)
	}

    const getApplyList = async () => {
        let params = {
			bdNo : store.state.admin.bdNo,
			search : searchText.value,
			pageNo : searchParamForApply.value.pageNo
        }
        store.commit('temp/setIsApply', true)
        await store.dispatch('admin/fetchAdminApply', params)
    }

	onMounted(async () => {
		store.commit('temp/clearSearchParam')
		store.commit('temp/clearSearchParamForApply')
		if(!isAdmin){
            alert("해당 페이지 접근권한이 없습니다.")
			history.back(-1 )
        }
        store.commit('admin/setBdNo', 0)
        store.commit('temp/setIsApply', false)
		getGridData()
	})

</script>

<style></style>