import { createRouter, createWebHistory } from 'vue-router'
// import dynamic from './dynamic'
// import store from '../store'
import Home from '../pages/home'
import admin from './admin'
import about from './about'
import development from './development'
import match from './match'
import member from './member'
import notice from './notice'
import news from './news'
import player from './player'
import store from '../store'
import memberBoard from './memberBoard'
import sponsor from './sponsor'

const router = createRouter({
    history: createWebHistory(),
    // routes: dynamic(store)
    routes: [
        {
            path: '/', 
            name: 'Home', 
            component: Home,
            beforeEnter: (to, from, next) => {
                // console.log("home router", to, from)
                // console.log("home store", store.state.treeMenu.tree)
                store.commit('temp/setIsMain', false)
                next()
            }
        },
        ...admin,
        ...about,
        ...development,
        ...match,
        ...member,
        ...notice,
        ...news,
        ...player,
        ...memberBoard,
        ...sponsor,

    ]
})

export default router;  
