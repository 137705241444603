<template>

	<div class="subtop">
		<div class="subtop-cont">
			<div class="subtop-title title-2">
				<p>자격증</p>
				<span>대한서핑협호의 자격증 정보를 확인할 수 있습니다.</span>
			</div>
		</div>
		<img class="subtop-img" src="@/assets/images/img/subtop-1.png">
	</div>
    <div class="sub-menu">
		<ul class="float-wrap">
			<li><router-link to="/development-level">자격증 안내</router-link></li>
			<li class="on"><router-link to="/development-coaching">Coaching and Intruction</router-link></li>
			<li><router-link to="/development-rescue">KSA surf rescue and CPR Test</router-link></li>
			<li><router-link to="/development-list">자격신청</router-link></li>
		</ul>
	</div>

	<!--서브내용-->
	<div class="sub-wrap">
		<div class="sub-cont">
			<p class="title-sub">Level Test</p>
			<div style="padding: 50px;
						text-align: center; 
						box-shadow:4px 4px 10px 0px rgba(124,124,124,.5)">
				<img :src="apiBaseUrl + '/images/sub/development/development_img02.png'" alt="서핑클럽">
			</div>
		</div>
	</div>
</template>

<script setup>
	import { useStore } from 'vuex'

	const store = useStore()
	
	const apiBaseUrl = process.env.VUE_APP_API_URL

</script>

<style></style>