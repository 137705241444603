<template>
    <div class="navi">
        <div class="navi-box wrap-sm">
            <a href="#">HOME</a>
            <a href="#">경기인정보</a>
            <span>가맹샵</span>
        </div>
    </div>
    <div class="sub-wrap sub-wrap-grey">
        <div class="sub-cont">
            <div class="search">
                <div class="title-2">
                    <p>가맹샵</p>
                    <span><em>지역별 가맹샵 또는 가맹샵명 검색으로 찾아보세요.</em></span>
                </div>
                <div class="search-box search-box-accent">
                    <input type="text" placeholder="검색어를 입력하세요">
                    <button type="button"><img src="@/assets/images/icon/icon-search-light.png" alt="검색"></button>
                </div>
            </div>
            
        </div>  
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>