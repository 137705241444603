<template>

    <!--회원가입-->
    <div class="member join">
        <div class="member-bg">
            <div class="member-box">
                <div class="title-2">
                    <p>마이페이지</p>
                </div>
                <div class="member-inputs">
                <!--아이디-->
                <div class="input-box">
                    아이디 : {{userData?.userId}}
                </div>

                <!--비밀번호-->
                <div class="input-box">
                    <label>비밀번호</label>
                    <customInput type="password" name="password" placeholder="비밀번호입력하세요" @updateInput="updateInput"/>
                </div>

                <!--비밀번호 확인-->
                <div class="input-box">
                    <label>비밀번호 확인</label>
                    <customInput type="password" name="passwordChk" placeholder="비밀번호 확인" @updateInput="updateInput"/>
                </div>

                <!--이름-->
                <div class="input-box">
                    <label>이름</label>
                    <customInput type="text" name="userName" placeholder="이름을 입력하세요" @updateInput="updateInput" 
                    :value="userData?.userName"/>
                </div>

                <!--이메일-->
                <div class="input-box">
                    <label>이메일</label>
                    <customInput type="text" name="userEmail" placeholder="이메일 주소를 입력하세요" @updateInput="updateInput"
                    :value="userData?.userEmail"/>
                </div>

                    <!-- 시도협회 -->
                    <div class="input-box">
                        <label>시도협회</label>
                        <select class="input-box" v-model="selectedOption" @change="updateSelect" :value="userData?.userArea">
                            <option value="서울특별시">서울특별시</option>
                            <option value="경기도">경기도</option>
                            <option value="인천광역시">인천광역시</option>
                            <option value="강원특별자치도">강원특별자치도</option>
                            <option value="충청북도">충청북도</option>
                            <option value="충청남도">충청남도</option>
                            <option value="대전광역시">대전광역시</option>
                            <option value="세종특별자치시">세종특별자치시</option>
                            <option value="전라북도">전라북도</option>
                            <option value="전라남도">전라남도</option>
                            <option value="광주광역시">광주광역시</option>
                            <option value="경상북도">경상북도</option>
                            <option value="경상남도">경상남도</option>
                            <option value="대구광역시">대구광역시</option>
                            <option value="울산광역시">울산광역시</option>
                            <option value="부산광역시">부산광역시</option>
                            <option value="제주특별자치도">제주특별자치도</option>
                        </select>
                    </div>

                <!--핸드폰-->
                <div class="input-box input-box-phone">
                    핸드폰 : {{userData?.userHp}}
                </div>

                <!--성별-->
                <div class="radio-wrap radio-wrap-vertical">
                    성별 : {{ userData?.gender == 'M' ? '남자' : '여자'}}
                </div>

                <!-- 등급 -->
                <div class="radio-wrap radio-wrap-vertical">
                    등급 : {{ userData?.userLevel == 1 ? '일반회원' :
                       userData?.userLevel == 2 ? '선수' :
                       userData?.userLevel == 3
                        ? '지도자' : '정회원'}}
                </div>

                <!--메일링 서비스-->
                <div class="radio-wrap">
                    <span class="radio-label">메일링 서비스</span>
                    <div class="radio-boxes">
                        <div class="radio-box">
                            <searchRadio 
                                name="mailYn" 
                                :radioId="'mailYn_y'"
                                :title="'수신'"
                                :radioValue="'Y'"
                                @updateRadio="updateInput"
                                :isChecked="userData?.mailYn == 'Y' ? true : false"
                                />
                        </div>
                        <div class="radio-box">
                            <searchRadio 
                                name="mailYn" 
                                :radioId="'mailYn_n'"
                                :title="'비수신'"
                                :radioValue="'N'"
                                @updateRadio="updateInput"
                                :isChecked="userData?.mailYn == 'N' ? true : false"
                                />
                        </div>
                    </div>
                </div>

                <!--문자 수신 여부-->
                <div class="radio-wrap">
                    <span class="radio-label">문자 수신 여부</span>
                    <div class="radio-boxes">
                        <div class="radio-box">
                            <searchRadio 
                                name="msgYn" 
                                :radioId="'msgYn_y'"
                                :title="'수신'"
                                :radioValue="'Y'"
                                @updateRadio="updateInput"
                                :isChecked="userData?.msgYn == 'Y' ? true : false"
                                />
                            </div>
                            <div class="radio-box">
                            <searchRadio 
                                name="msgYn" 
                                :radioId="'msgYn_n'"
                                :title="'비수신'"
                                :radioValue="'N'"
                                @updateRadio="updateInput"
                                :isChecked="userData?.msgYn == 'N' ? true : false"
                                />
                        </div>
                    </div>
                </div>

                <!--정보공개-->
                <div class="radio-wrap">
                    <span class="radio-label">정보공개</span>
                    <div class="radio-boxes">

                        <div class="radio-box">
                            <searchRadio 
                                name="infoYn" 
                                :radioId="'infoYn_y'"
                                :title="'수신'"
                                :radioValue="'Y'"
                                @updateRadio="updateInput"
                                :isChecked="userData?.infoYn == 'Y' ? true : false"
                                />
                        </div>
                        <div class="radio-box">
                            <searchRadio 
                                name="infoYn" 
                                :radioId="'infoYn_n'"
                                :title="'비수신'"
                                :radioValue="'N'"
                                @updateRadio="updateInput"
                                :isChecked="userData?.infoYn == 'N' ? true : false"
                                />
                        </div>
                    </div>
                </div>


                </div>
                <div class="input-btns">
                    <button type="button" class="btn btm-btn accent" @click="update">수정하기</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { useStore } from 'vuex';
    import { computed, onMounted, onUnmounted } from 'vue';
    import customInput from '@/components/common/input/customInput.vue'
    import searchRadio from '@/components/common/radio/searchRadio.vue'
    import { userUpdate } from '@/api/member'
    import router from '@/router'
    import { ref } from 'vue';

    const store = useStore()
    const isLogin = store.state.temp.isLogin
    const selectedOption = ref('');

    const updateInput = (newValue) => {
        store.commit('member/updateMember', newValue)
    }

    const updateSelect = () => {
        store.state.member.member.userArea = selectedOption.value;
    }

	const userData = computed (() => {
		return store.state.member.userInfo
    })

    // 회원정보 수정
    const update = async () => {
        let rtn = {}
        rtn = await userUpdate(store.state.member.member)
        if(rtn?.result == 'ok') {
            alert(rtn.resultMsg)
            store.commit('member/clearMember')
            store.commit('member/cleanUserInfo')
            router.push({
                name: 'Home'
            }) 
        }
    }

    onMounted(() => {
        if(!isLogin){
            history.back(-1 )
        }
        store.commit('member/clearMember')
        store.commit('member/cleanUserInfo')
        store.dispatch('member/fatchUserInfo',{userId : store.state.temp.userInfo.userId})
    })
    
	onUnmounted(() => {
        store.commit('member/clearMember')
	})
</script>

<style></style>