import adminApply         from '@/pages/admin/apply'
import adminMember         from '@/pages/admin/member'
import adminContest        from '@/pages/admin/contest'

export default [
  {
    path: "/admin-apply",
    name: "admin-apply",
    component: adminApply,
  },
  {
    path: "/admin-member",
    name: "admin-member",
    component: adminMember,
  },
  {
    path: "/admin-contest",
    name: "admin-contest",
    component: adminContest,
  },
];
