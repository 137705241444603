<template>
    <input type="radio" 
        :id="radioId"
        :name="name"
        :value="radioValue"
        :checked="isChecked"
        @change="updateRadio"
    >
    <label :for="radioId">{{ title }}</label>
</template>

<script>
export default {
    props: {
        name: {
            type: String
        }, 
        radioId : {
            type : String,
        },
        isChecked : {
            type : Boolean,
            default : false
        },
        title: {
            type: String
        },
        selectValue: {
            type: String
        },
        radioValue: {
            type: String
            
        }
    },
    setup(props, { emit }){
        
        const updateRadio = (e) => {
            const obj = {
                index : props.index, 
                id: props.radioId,
                type: props.name,
                value: e.target.value
            }
            emit('updateRadio', obj)
        }
        
        return {
            updateRadio
        }

    }
}
</script>

<style scoped></style>
