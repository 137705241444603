import join from       '@/pages/member/join'
import policy from       '@/pages/member/policy'
import login from       '@/pages/member/login'
import mypage from       '@/pages/member/mypage'

export default [
    {
        path: '/member-join',
        name: 'member',
        component: join
    },
    {
        path: '/member-policy',
        name: 'policy',
        component: policy
    },
    {
        path: '/member-login',
        name: 'login',
        component: login
    },
    {
        path: '/mypage',
        name: 'mypage',
        component: mypage
    }
]
