<template>
    <!--게시판리스트-->
    <div class="board-list board-list-sm table-overflow-md">
        <table>
            <colgroup>
                <col v-for="(item, index) of gridInfo.colstyle" :width="item" :key="index">
            </colgroup>
            <tr>
                <th v-for="(item, index) of gridInfo.header" :key="index">
                    {{ item }}
                </th>
            </tr>
            <tr v-for="(member, index) of dataList" :key="index">
                <template v-for="(cnt, idx) in gridInfo.cell" :key="idx">
                    <td :class="cnt.class" style="padding:0%; text-align: center;">
                        <!-- 회원등급 -->
                        <template v-if="cnt.updown">
                            <button type="button" class="minus num" @click="addMinus('minus', member)">-</button>
                            <span class="num" style="float:left;">
                                {{member[cnt.key]}}
                            </span>    
                            <button type="button" class="plus num" @click="addMinus('plus', member)">+</button>
                        </template>
                        
                        <!-- 관리자 여부 -->
                        <template v-else-if="cnt.admin">
                            <input :id="'N_' + index" type="radio" v-model="member.adminYn" value="N">
                            <label :for="'N_' + index">아니요 </label>
                            <input :id="'Y_' + index" type="radio" v-model="member.adminYn" value="Y">
                            <label :for="'Y_' + index">예 </label>
                        </template>
                        
                        <!-- 삭제여부 -->
                        <template v-else-if="cnt.del">
                            <button type="button" class="saveBtn" @click="del(member)">탈퇴</button>
                        </template>
                        
                        
                        <!-- 저장여부 -->
                        <template v-else-if="cnt.save">
                            <button type="button" class="saveBtn" @click="saveChange(member)">저장</button>
                        </template>

                        <template v-else>
                            {{member[cnt.key]}}
                        </template>
                    </td>
                </template>
            </tr>
        </table>
    </div>
</template>

<script setup>
    import { useStore } from 'vuex'

    const store = useStore()

    const props = defineProps({
        gridInfo : Object,
        dataList : Array
    })

    const emit = defineEmits(['deleteMember'])

	const addMinus = (cmd, member) => {
		if(cmd == 'minus') {
			if(member.userLevel > 0) {
				member.userLevel--;
			}
		} else {
			if(member.userLevel < 3) {
				member.userLevel++;
			}
		}
	}

	const del = async (data) => {
        emit("deleteMember", data)
	}
    
	const saveChange = async (data) => {
		let params = {
				userId : data.userId,
				userLevel : data.userLevel,
				adminYn : data.adminYn
		}
        console.log(params)

        await store.dispatch('admin/updateUserLevel', params)
	}

</script>

<style>
.minus{float: left; text-indent: -8888px; background: url("@/assets/images/sub/minus.jpg")no-repeat center center; }
.plus{float: left; text-indent: -8888px; background: url("@/assets/images/sub/plus.jpg")no-repeat center center; }
.num {border:1px solid #b7b7b7; width: 22px; height: 25px; margin-left: 3px;}
.selected{font-size: 0.875rem; font-weight: 700; text-align: left;width:25px;height:25px;}
.saveBtn{font-size: 0.875rem; font-weight: 700; text-align: center;width:50px;height:25px; border:1px solid #b7b7b7; }
</style>