<template>
    <!--서브내용-->
    <div class="sub-wrap">
        <div class="sub-cont">
            <p class="title-sub">회원자격</p>

            <!--게시판 뷰-->
            <div class="board-view">
                <div class="board-view-top">
                    <p>{{ pageData?.bdTitle }}</p>
                    <div class="board-view-info">
                        <div class="board-view-detail">
                            <span>게시일</span>
                            <em>{{ pageData?.regDt }}</em>
                        </div>
                        <!-- <div class="board-view-detail">
                            <span>조회수</span>
                            <em>{{ pageData?.bdHit }}</em>
                        </div> -->
                    </div>
                </div>
                <dl class="board-cell">
                    <dt>첨부파일</dt>
                    <dd v-for="item in pageData?.boardFileList" :key="item">
                        <button type="button"
                            @click="downloadUtil(item?.filePath, item?.fileOriginName)">{{ item?.fileOriginName }}</button>
                    </dd>
                </dl>
                <!-- textArea 영역 -->
                <div class="board-view-middle">
                    <div class="board-view-cont">
                        <!-- <template v-if="pageData?.bdCont != null">
                            <div v-dompurify-html="pageData?.bdCont">
                                {{ pageData?.bdCont }}
                            </div>
                        </template> -->
                        <div v-dompurify-html="getEmbededCont(pageData?.bdCont)">
                        </div>
                    </div>
                </div>

                <Reply :replyList="pageData?.replyList" :userInfo="userInfo" @regReply="regReply" @updateReply="updateReply"
                    @deleteReply="deleteReply" v-model="replyContent" />

                <div style="float">
                    <button class="btn-sm" @:click="updateBoard(pageData?.bdNo)" v-if="isAdmin">게시글수정</button>
                    <button class="btn-sm" @:click="deleteBoard(pageData?.bdNo)" v-if="isAdmin">게시글삭제</button>
                </div>

                <PrevNext :pageData="pageData" @goPage="goPage" />

            </div>
                <button type="button" class="btn btm-btn" @click="back">목록으로</button>
        </div>
    </div>
</template>

<script setup>
import PrevNext from '@/components/common/prevnext/prevNext'
import Reply from '@/components/common/reply/reply'
import { del } from '@/api/axios'
import { computed, ref, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { downloadUtil, getCookie } from '@/util/util.js'

const store = useStore()
const route = useRoute()
const router = useRouter()
const replyContent = ref('')
const cookie = getCookie(document.cookie)
const apiBaseUrl = process.env.VUE_APP_API_URL;


const isAdmin = computed(() => {
    return store.state.temp.isAdmin
})

const userInfo = computed(() => {
    return store.state.temp.userInfo
})

const searchParam = computed(() => {
    return store.state.temp.searchParam
})

const pageData = computed(() => {
    return store.state.detail.detailData
})

const goPage = async (param) => {
    await store.dispatch('detail/fetchBoardDetail', { bdNo: param.no, bdTypeCd: param.boardType })
}

const getEmbededCont = (cont) => {

    var parser = new DOMParser();
    var doc = parser.parseFromString(cont, 'text/html');

    doc.querySelectorAll('img').forEach(img => {
        const src = img.getAttribute('src');
        img.setAttribute('src', apiBaseUrl + src)
    })

    return doc.body.innerHTML;
}

const updateBoard = (bdNo) => {
    const url = process.env.VUE_APP_API_URL + "/notice/update?bdTypeCd=" + route.query?.boardType + "&bdNo=" + bdNo + '&sessionId=' + cookie.sessionId
    const popup = window.open(url
        , '_blank'
        , 'chrome=1 ,centerscreen=1, innerWidth=1000, innerHeight=720'
        , false)
}

const deleteBoard = async (bdNo) => {
    if (confirm("정말 삭제하시겠습니까?")) {
        let rtn = await del('/board/delete', { bdNo: bdNo, bdWriterId: store.state.temp.userInfo.userId })
        // console.log(rtn)
        if (rtn.data.result === 'ok') {
            router.push({
                // path: '/memberBoard-list',                     
                params: searchParam?.value.pageNo
                
            })
            router.go(-1)
        }
    }
}

const back = computed(() => {
    return history.back()
})

const regReply = async () => {
    let param = {
        bdNo: route.query?.no,
        replyCont: replyContent.value
    }
    await store.dispatch('detail/fetchBoardInsertReply', param)
    await store.dispatch('detail/fetchBoardDetail', { bdNo: route.query?.no, bdTypeCd: route.query?.boardType })
    replyContent.value = '';
}

const updateReply = async (item) => {
    let param = {
        replyNo: item.replyNo,
        replyCont: item.replyPass
    }
    await store.dispatch('detail/fetchBoardUpdateReply', param)
    await store.dispatch('detail/fetchBoardDetail', { bdNo: route.query?.no, bdTypeCd: route.query?.boardType })
}

const deleteReply = async (replyNo) => {
    if (confirm('정말 삭제 하시겠습니까?')) {
        await store.dispatch('detail/fetchBoardDeleteReply', { replyNo: replyNo })
        await store.dispatch('detail/fetchBoardDetail', { bdNo: route.query?.no, bdTypeCd: route.query?.boardType })
    }
}

const getMessgage = async (e) => {
    if (e.origin == process.env.VUE_APP_API_URL) {
        await store.dispatch('detail/fetchBoardDetail', { bdNo: route.query?.no, bdTypeCd: route.query?.boardType })
    }
}

onMounted(async () => {
    await store.dispatch('detail/fetchBoardDetail', { bdNo: route.query?.no, bdTypeCd: route.query?.boardType })
    // 글쓰기, 수정하기 이벤트리스너 체크
    window.addEventListener('message', getMessgage)
})

onUnmounted(() => {
    // 페이지 닫힐 때 해당 리스너 삭제
    window.removeEventListener('message', getMessgage)
})
</script>

<style></style>