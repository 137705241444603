import { getMainData } from '@/api/auth'
import { delCookie } from '@/util/util'
import router from '@/router'

export default {
    namespaced: true, 
    state: {
        isAdmin: false,
        isApply: false,
        isLogin: false,
        isMain : false,
        searchParam : {
            pageNo : 1,
            search : ''
        },
        searchParamForApply : {
            pageNo : 1,
            search : ''
        },
        userInfo : {
            userId : '',
            userName : '',
            userLevel : 0
        },
        main : {}
    },
    mutations: {
        setIsAdmin(state, payload) {
            state.isAdmin = payload
        },
        setIsApply(state, payload) {
            state.isApply = payload
        },
        setIsLogin(state, payload) {
            state.isLogin = payload
        },
        setIsMain(state, payload){
            state.isMain = payload
        },
        setSearchParam(state, payload) {
            state.searchParam[payload.key] = payload.value
        },
        setUserInfo(state, payload) {
            state.userInfo[payload.key] = payload.value
        },
        setMainData(state, payload) {
            state.main = payload
        },
        clearSearchParam (state) {
            state.searchParam.pageNo = 1
            state.searchParam.search = ''
        },
        clearUserInfo(state) {
            state.userInfo = {
                userId : '',
                userName : '',
                userLevel : 0
            }
        },       
        clearMainData(state) {
            state.main = {}
        },
        setSearchParamForApply(state, payload) {
            state.searchParamForApply[payload.key] = payload.value
        },
        clearSearchParamForApply (state) {
            state.searchParamForApply.pageNo = 1
            state.searchParamForApply.search = ''
        },
    },actions : {
        async fetchMainData ({state, commit}, payload) {
            commit('clearMainData')
            let rtn = {}
            rtn = await getMainData(payload)
            commit('setMainData', rtn)
        },
        async clearSessionOut ({state, commit}) {
            delCookie("sessionId")
            commit('setIsLogin', false)
            commit('setIsAdmin', false)
            commit('clearUserInfo')
			router.push({name:'Home'})
        },
    }
}