import { get, post } from './axios'

export const getNoticeList = async (params) => {
    const { status, data } = await get("/notice/list", params)

    if (status === 200 || status === 201) {
        return data
    }
}

export const getNewsList = async (params) => {
    const { status, data } = await get("/news/list", params)

    if (status === 200 || status === 201) {
        return data
    }
}

export const getMatchList = async (params) => {
    const { status, data } = await get("/event/list", params)

    if (status === 200 || status === 201) {
        return data
    }
}

export const getDevelopmentList = async (params) => {
    const { status, data } = await get("/development/list", params)

    if (status === 200 || status === 201) {
        return data
    }
}

export const getContestList = async (params) => {
  const { status, data } = await get("/event/list", params);

  if (status === 200 || status === 201) {
    return data;
  }
};

export const getGalleryList = async (params) => {
    const { status, data } = await get("/gallery/list", params)

    if (status === 200 || status === 201) {
        return data
    }
}

export const getMemberList = async (params) => {
    const { status, data } = await get("/admin/member/getMemberList", params)
    if (status === 200 || status === 201) {
        return data
    }
}

export const getMemberBoardList = async (params) => {
  const { status, data } = await get("/memberBoard/list", params);

  if (status === 200 || status === 201) {
    return data;
  }
}

export const getJoinInfoList = async (params) => {
  const { status, data } = await get("/memberBoard/join", params);

  if (status === 200 || status === 201) {
    return data;
  }
}

export const getFeeList = async (params) => {
  const { status, data } = await get("/memberBoard/fee", params);

  if (status === 200 || status === 201) {
    return data;
  }
}

export const getRankingList = async (params) => {
  const { status, data } = await get("/match/ranking", params);

  if (status === 200 || status === 201) {
    return data;
  }
};


