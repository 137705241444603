import { get, post } from './axios'

export const deleteUser = async (params) => {
    const { status, data } = await post("/admin/member/deleteUser", params)

    if (status === 200 || status === 201) {
        return data
    }
}

export const updateUserLevel = async (params) => {
    const { status, data } = await post("/admin/member/updateUserLevel", params)

    if (status === 200 || status === 201) {
        return data
    }
}

export const getApplyList = async (params) => {
    const { status, data } = await get("/admin/apply/selectApplyList", params)
    
    if (status === 200 || status === 201) {
        return data
    }
}

export const cancelApply = async (params) => {
    const { status, data } = await post("/admin/apply/cancelApply", params)

    if (status === 200 || status === 201) {
        return data
    }
}