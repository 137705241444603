<template>
    <!--게시판리스트-->
    <div class="board-list board-list-sm table-overflow-md">
        <table>
            <colgroup>
                <col v-for="(item, index) of gridInfo.colstyle" :width="item" :key="index">
            </colgroup>
            <tr>
                <th v-for="(item, index) of gridInfo.header" :key="index">
                    {{ item }}
                </th>
            </tr>
            <tr v-for="(vo, index) of dataList" :key="index">
                <template v-for="(cnt, idx) in gridInfo.cell" :key="idx">
                    <td :class="cnt.class" style="padding:0%; text-align: center;">
                        <!-- 링크 여부 -->
                        <template v-if="cnt.link">
                            <span style="cursor: pointer;" @click="getApplyList(vo)">
                                {{vo[cnt.key]}}
                            </span>
                        </template>

                        <template v-else>
                            {{vo[cnt.key]}}
                        </template>
                    </td>
                </template>
            </tr>
        </table>
    </div>
</template>

<script setup>
    import { useStore } from 'vuex'

    const store = useStore()

    const props = defineProps({
        gridInfo : Object,
        dataList : Array
    })

    const getApplyList = async (data) => {
        let params = {
			bdNo : data.bdNo,
			pageNo : data.pageNo
        }
        store.commit('admin/setBdNo', data.bdNo)
        store.commit('temp/setIsApply', true)
        await store.dispatch('admin/fetchAdminApply', params)
    }
    
    const emits = defineEmits([])

</script>

<style>
</style>