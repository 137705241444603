<template>

	<div class="subtop">
		<div class="subtop-cont">
			<div class="subtop-title title-2">
				<p>자격증</p>
				<span>대한서핑협호의 자격증 정보를 확인할 수 있습니다.</span>
			</div>
		</div>
		<img class="subtop-img" src="@/assets/images/img/subtop-1.png">
	</div>

	<div class="sub-menu" style="margin-top: 15px;">
        <ul class="float-wrap" style="width: 50%;">
			<!--
			<li><router-link to="/development-coaching">Coaching and Intruction</router-link></li>
			<li><router-link to="/development-rescue">KSA surf rescue and CPR Test</router-link></li>
			-->
			
			<!-- <li><router-link to="/development-level">자격증 안내</router-link></li>
			<li class="on"><router-link to="/development-list">자격신청</router-link></li> -->
			<div class="tab-container tab-box">
                <div class="tab tab-2">
                    <button type="button" @click="setTab(1)" :class="'on'">자격신청</button>
                    <button type="button" @click="setTab(2)" :class="''">자격증 안내</button>
                </div>
			</div>
		</ul>
	</div>

	<!--서브내용-->
	<div class="sub-wrap">
		<div class="sub-cont">
			<p class="title-sub">자격신청</p>

			<BoardTop :gridInfo="developmentJson" @getGridData="getGridData" v-model="searchText"/>

			<Grid :gridInfo="developmentJson" :dataList="developmentList" />

			<Pagination v-if="developmentJson.pagination" :gridInfo="developmentJson" :editorType="'notice'" :boardType="developmentJson?.boardType" @getGridData="getGridData" />

		</div>
	</div>
</template>

<script setup>
	import BoardTop from '@/components/common/grid/boardtop'
	import Grid from '@/components/common/grid/grid'
	import Pagination from '@/components/common/grid/pagination'
	import developmentJson from '@/models/gridInfo/development.json'
	import { ref, computed, onMounted, onUnmounted } from 'vue'
	import { useStore } from 'vuex'
	import { useRouter } from 'vue-router'

	const store = useStore()
	const router = useRouter()
	
	const searchText = ref('')

	const developmentList = computed(() => {
		return store.state.grid.gridData
	})

	const searchParam = computed(() => {
		return store.state.temp.searchParam 
	})

	const getGridData = async () => {
		let param = {pageNo : searchParam.value.pageNo, search : searchText.value}
		await store.dispatch('grid/fetchDevelopment', param)
	}
	
	const getMessgage = (e) => {
		if(e.origin == process.env.VUE_APP_API_URL) {
			getGridData()
		}
	}

	onMounted(async () => {
		store.commit('temp/clearSearchParam')
		getGridData()
		// 글쓰기, 수정하기 이벤트리스너 체크
		window.addEventListener('message', getMessgage)
	})

	onUnmounted(() => {
		store.commit('temp/clearSearchParam')
		// 페이지 닫힐 때 해당 리스너 삭제
		window.removeEventListener('message', getMessgage)
	})

	//탭
	const activeTabId = ref(1)

    const setTab = (tabId) => {
        activeTabId.value = tabId
		if(tabId == 1) {
			router.push({ path : '/development-list' })
		}	
		if (tabId == 2) {
			router.push({ path: '/development-level' })
		}
    }  
</script>

<style></style>