import { get, post } from './axios'

export const getBoardDetail = async (params) => {
    const { status, data } = await get("/board/detail", params)

    if (status === 200 || status === 201) {
        return data
    }
}

export const insertReply = async (params) => {
    const { status, data } = await post("/board/insertReply", params)

    if (status === 200 || status === 201) {
        return data
    }
}

export const updateReply = async (params) => {
    const { status, data } = await post("/board/updateReply", params)

    if (status === 200 || status === 201) {
        return data
    }
}

export const deleteReply = async (params) => {
    const { status, data } = await post("/board/deleteReply", params)

    if (status === 200 || status === 201) {
        return data
    }
}