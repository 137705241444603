<template>
    <!--상단-->
    <div class="board-top float-wrap">
        <div class="board-top-left">
            <p class="cont-total accent">전체 <span>{{ pagingBean?.totalRecordCount }}</span> 건</p>
        </div>
        <div class="board-top-right" v-if="gridInfo.search">
            <div class="search-box search-box-accent">
                <input type="text" placeholder="검색어를 입력하세요" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" @keyup.enter="gridLoad">
                <button type="button" @click="gridLoad">
                    <img src="@/assets/images/icon/icon-search-light.png" alt="검색">
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { computed } from 'vue'
    import { useStore } from 'vuex'
    
    const store = useStore()

    const pagingBean = computed(() => {
        return store.state.grid.pagination 
    })
    
    const props = defineProps({
        modelValue : String,
        gridInfo : Object
    })

    const emit = defineEmits(['getGridData','update:modelValue'])

    const gridLoad = () => {
        store.commit('temp/setSearchParam', {key : 'pageNo', value : 1})
        emit('getGridData')
    }

</script>

<style>

</style>