import gallery from       '@/pages/notice/gallery'
import list from       '@/pages/notice/list'
import qna from       '@/pages/notice/qna'
import view from       '@/pages/notice/view'

export default [
    {
        path: '/notice-gallery', 
        name: 'gallery',
        component: gallery,
    },
    {
        path: '/notice-list',
        name: 'notice',
        component: list
    },
    {
        path: '/notice-qna',
        name: 'qna',
        component: qna
    },
    {
        path: '/notice-view',
        name: 'view',
        component: view
    }
]
