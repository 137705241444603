<template>
	<div class="subtop">
		<div class="subtop-cont">
			<div class="subtop-title title-2">
				<p>대회정보</p>
				<span>최신 대회정보를 확인 하세요.</span>
			</div>
		</div>
		<img class="subtop-img" src="@/assets/images/img/subtop-1.png">
	</div>
    
    <div class="sub-menu" style="margin-top: 15px;">
        <ul class="float-wrap" style="width: 50%;">
            <!-- <li><router-link to="/match-rule">대회운영</router-link></li>
			<li class="on"><router-link to="/match-list">대회소식</router-link></li> -->
			<div class="tab-container tab-box">
                <div class="tab tab-3">
                    <button type="button" @click="setTab(2)" :class="''">대회소식</button>
                    <button type="button" @click="setTab(3)" :class="''">KSA랭킹</button>
                    <button type="button" @click="setTab(1)" :class="'on'">대회운영</button>
                </div>
			</div>
		</ul>
    </div>    
    <!--서브내용-->
    <div class="sub-wrap sub-wrap-grey">
        <div class="sub-cont">
            <p class="title-sub">대회운영</p>
        
            <!--관련규정-->
            <div class="tab-container tab-index">
                <div class="tab">
                    <button type="button" :class="{ 'on' : tabs === 'normal' }" @click="tabs='normal'">일반운영</button>
                    <button type="button" :class="{ 'on': tabs === 'rules' }" @click="tabs='rules'">경기 경영 및 룰</button>
                    <button type="button" :class="{ 'on': tabs === 'point' }" @click="tabs='point'">심판 경영 및 점수 책정방법</button>
                </div>
                <div class="tab-contents">
                    
                    <!--일반 운영-->
                    <div class="tab-cont" :class="{ 'active' : tabs === 'normal'}">		
                        <div class="desc">
                            <h4>일반운영</h4>
                            <p><em>A. 국내 선수권 대회 및 국내 개최 KSA 공인 국제 대회</em></p>
                            <ul>
                                <li>1. 빈도 및 적격성</li>
                                <li>국내에서 KSA가 개최하는 선수권 대회들은 KSA가 인정하는 시.도협회 및 산하단체에 소속되어 있는 선수들을 위한 것.</li>
                            </ul>
                            <ul>
                                <li>2. 종목 및 입상</li>
                                <li>KSA에서 주최하는 대회 및 이벤트는 아래와 같은 종목으로 나이 기준을 정하여 진행한다. 국내 선수권대회 및 국제대회 모든 KSA관련 이벤트에 적용</li>
                                <li>
                                    <table style="width:100%; max-width:340px;">
                                        <tr>
                                            <td>남자 오픈부 :</td>
                                            <td>남성, 전 연령</td>
                                            <td>(Open Men)</td>
                                        </tr>
                                        <tr>
                                            <td>여자 오픈부 :</td>
                                            <td>여성, 전 연령</td>
                                            <td>(Open Women)</td>
                                        </tr>
                                        <tr>
                                            <td>청소년 :</td>
                                            <td>남 또는 여아, 18세 이하</td>
                                            <td>(Junior)</td>
                                        </tr>
                                    </table>
                                </li>
                            </ul>
                            <ul>
                                <li>단체전</li>
                                <li>단체전은 KSA의 소규모 팀(대회 참여인원 4인)을 우선으로 진행하게 되며 나이 제한은 없으며(주니어~ 그랜드 마스터) 선수당 대회 참여 기회가 제한된다. 상기 각 종목 개인전의 결승 진출자들은 금/은/동메달 또는 트로피와 상장을 순위에 따라 수여 받음. 단체전은 상위 3개 팀의 멤버들에게 순위에 따라 금/은/동 메달 또는 트로피와 상장 수여됨. </li>
                            </ul>
                            <ul>
                                <li>3. 등록(선수권 대회)</li>
                                <li>경기자는 오직 한 시도만을 대표할 수 있음. 두 개 이상의 시도단체의 등록자는 대표할 시도를 결정해야 하며 KSA인증 시도협회장의 추천을 얻어야 한다.</li>
                            </ul>
                            <ul>
                                <li>4. 중복출전</li>
                                <li>KSA에서 대회 전 사전 고지하는 대회는 경기자들의 한 종목 이상 중복출전이 가능하다. 연속적으로 진행되는 예선전일 경우에도 오픈 부문 및 다른 부문 경기자들에게 주어질 특별 참작 사항이 없을 것임을 공시하며, 대회 진행상 가능한 한 경기자에게는 해변으로 돌아가 경기복을 갈아입을 시간이 주어질 수 있다. </li>
                            </ul>
                        </div>
                    </div>
                    
                    <!--경기 경영 및 룰-->
                    <div class="tab-cont"  :class="{ 'active': tabs === 'rules' }">
                        <div class="desc">
                            <h4>일반운영</h4>
                            
                            <div class="tab-container tab-text">
                                <div class="tab">
                                    <button type="button" :class="{ 'on': rulesTabs === 'match' }" @click="rulesTabs = 'match'">경기경영</button>
                                    <button type="button" :class="{ 'on': rulesTabs === 'comRules' }" @click="rulesTabs = 'comRules'">대회 규칙</button>
                                    <button type="button" :class="{ 'on': rulesTabs === 'disturb' }" @click="rulesTabs = 'disturb'">방해</button>
                                    <button type="button" :class="{ 'on': rulesTabs === 'misson' }" @click="rulesTabs = 'misson'">대회장의 임무</button>
                                </div>
                                <div class="tab-contents">
                                
                                    <!--경기경영-->
                                    <div class="tab-cont" :class="{ 'active' : rulesTabs === 'match' }" @click="rulesTabs = 'match'">
                                        <dl>
                                            <dt>대회등록/대체</dt>
                                            <dd>대회등록은 적어도 대회가 개최되기로 한 시점의 1개월(30일/31일) 전에 반드시 대한서핑협회 앞으로 제출되고 모든 참가선수가 명단에 명시되어야 함. 대회에 앞서 14일 이내로는 KSA의 대회장 승인 없이 새로이 참가 지원할 수 없음. 등록 변경사항에 대해서는 대회에 앞선 경기자 회의에서 최종 결정되며 이 시점에서 시드 배정이 끝난 선수는 대회 첫 번째 라운드에 참석하지 않으면 경기자는 출전권을 박탈당한다. <br>탈락 형태가 적용될 시, 미 참석한 선수들은 패자부활전의 첫 번째 경기에 우선적으로 참가한다. 선수권 대회에서 후보 선수는 부상이나 질병에 관한 의사 진단서가 있을 경우 경기 시작 동시 투입이 가능하다. 대체 선수가 투입된 이상 원래 참가하고자 했던 선수는 경기에 참가 할 수 없다. 경기를 한번 치른 선수는 더 이상 후보선수가 자신을 대체할 수 없다. 최초 추첨이 한 번 시행되고 나서는 오지 않는 선수를 고려한 번복이 있을 수 없음.</dd>
                                        </dl>
                                        <dl>
                                            <dt>시드배정(시드: 우수한 선수끼리 처음부터 맞서지 않도록 대진표를 작성하는 일)</dt>
                                            <dd>종목별 각 부문별 시드 차례는 지난 대회에서의 랭킹에 따라 대회장에게 보고 된바 선수들의 최종 결과를 기초로 한다. <br>지난 대회에 경쟁하지 않았던 선수들은 명단에서 가장 나중에 추첨 된다. 첫 번째 문단에 명기된 시드 순서대로 시행, 첫 번째 라운드가 결정된다. 대회 내에서, 예선 경기 진출하는 참가자들은 이전 예선에서 취득한 포지션에 따라 각 잇따른 예선 조에 시드 되게 된다. <br>셋 혹은 네 명의 선수를 위한 조에 단 두 명의 선수만이 있는 경우 그 조는 경기하지 않고 선수들은 그들이 직전 라운드에 받은 포인트에 기초에 포지션을 배당받을 것이며, 첫 번째 라운드 조 경우는 시드에 따라 포지션을 배당받는다.</dd>
                                        </dl>
                                        <dl>
                                            <dt>경기 포맷</dt>
                                            <dd>대회는 KSA 대회위원회에 의해 결정된 포맷으로 구성된다. 대회 및 결승전은 서로 다른 장소나 같은 장소에서 열릴 수 있다. 결승전은 4명의 선수로 구성된 조 시스템으로 진행된다. 결승전을 치르지 못하거나 끝마칠 수 없는 상황 발생 시에는 KSA 대회장이 대회를 통해 축적된 순위 결과를 바탕으로 부문 승자를 결정할 수 있다. 주최측의 요청 시 결승전을 위해 최종 대회 일자가 유보될 수 있다.</dd>
                                        </dl>
                                        <dl>
                                            <dt>포인트(점수)</dt>
                                            <dd>국내대회의 경우 경기를 마치는 성적에 따라 각 선수들은 개개인의 포인트를 쌓게 된다. 베이스 포인트 1000점은 각 부문에서 경기 참가하는 선수 수대로 늘어나게 되며 모든 포인트는 유효한 순위로 분산된다. 베이스 포인트 할당은 첨부된 표를 참고. 전체 대회 후 최종 포인트 승리자는 가장 높은 포인트의 합을 가진 사람 및 팀이며 open부분의 최종 1위인자가 국내 챔피언으로 지목되며 랭킹 1위 트로피와 증서를 수여 받게 됨. <br>소규모 팀 부분 우승팀은 대한민국 챔피언 팀으로 지목되며 챔피언 쉽 트로피가 수여된다. <br>팀 별로 각 부문별 최대 4명의 선수들을 출전시킬 수 있으며 각 팀 별 참가비가 요구됨. 그러나 팀의 종합 최종 순위 및 랭킹에는 상위 3명의 결과만이 반영되며 부문당 트로피 및 메달 수여 시 4명 모두에게 개인 메달이 수여된다.</dd>
                                        </dl>
                                        <dl>
                                            <dt>대회 임원</dt>
                                            <dd>
                                                <ol class="ol-lower-alpha">
                                                    <li>대회장과 심판장 <br>전문성, 국제성, 지역별의 기준에 기초하여 KSA 심판위원회에 의해 선출됨.</li>
                                                    <li>국제/단체/지정 심판 <br>대회 지원 이후 KSA에 의해 지목된 모든 심판들은 KSA에 종속되며 심판 지원은 대회위원회의 지정 일자까지 제출해야 하며 지켜지지 않은 경우 심판 임명 기회를 얻지 못하게 됨. <br>선발 위원단들. 은 전문성, 국제성, 지역별 기준에 기초하여 심판 위원들을 추천. KSA 심판위원회는 KSA 경기력향상위원회의 추천을 얻어 마지막 의사 결정을 추진한다.</li>
                                                    <li>KSA 룰의 선언 처리는 각 대회 참가 임원들에 의해 완결된다.</li>
                                                </ol>
                                            </dd>
                                        </dl>
                                        <dl>
                                            <dt>ssISA 공식대회 프로토콜</dt>
                                            <dd>KSA가 우선적으로 적용하게 되는 주최측의 가장 중요한 책무는 대회기간 동안 KSA와 KSA 멤버에 대한 다양한 서비스를 제공하는 것임. <br>공식 대회 손목밴드. 참가비 납부 시 대회 운영본부를 통해 	  발권됨. 1인 참가비 당 1개의 밴드 지급. 양도 불가. 대회 기간 동안 반드시 손목에 착용. 대회 참여 여부를 식별하는 주요 수단이므로 주의할 것. 밴드 착용함으로써 대회 안전요원에게 자신의 대회 	 전반 시설 및 기능을 이용할 권리를 표명할 수 있음. 손목 밴드는 개개인의 소관이며 교환 또는 다른 사람에게 일시적 양도가 불가함. 훼손 시 교환가능.
                                            </dd>
                                            <dd>참여자 공식 프로토콜 <br>
                                                <ol class="ol-korean">
                                                    <li>참가비 지불 후 공식 유니폼 착용 가능</li>
                                                    <li>참가비 지불 후 개회식 참석 가능</li>
                                                    <li>참가비 지불 후 숙소-대회장 각각의 행사에 관련된 할인, 기타혜택 서비스 이용 가능</li>
                                                    <li>참가비 지불 후 공식 손목밴드 지급 받으며 대회장 내 지정된 구역 출입 및 기타 연관된 활동 가능.</li>
                                                </ol>
                                            </dd>
                                            <dd>
                                                알림: 대회 참가비 납부로 납부자가 공식적으로 KSA 대회에 참가자 신분과 신원, 안전 등을 보장받음. <br>
                                                KSA는 국가대표선수에 의해 제공되는 후원그룹의 아이템 및 의류들을 홍보 용도로써 명확히 할 것을 권장.
                                            </dd>
                                        </dl>
                                        <dl>
                                            <dt>KSA 대회 참가비</dt>
                                            <dd>참가비는 KSA 대회위원회 결정 사항. KSA 지시에 따라 각 참여 인원들은 참가비를 납부. 1개 이상의 부문에서 경쟁하는 선수는 각 부문마다 참가비를 내도록 요구됨. 늦게 낼 시 상부 결정에 따라 불이익을 당할 수 있음.</dd>
                                        </dl>
                                        <dl>
                                            <dt>회의</dt>
                                            <dd>KSA 대회장은 대회 심판장과 더불어 심판과 대회위원회의 미팅을 KSA 대회에 앞서 열 수 있다. 모든 참가 또는 관계된 개인은 회의 후 대회의 중요 반영결과 보고 KSA 대회장은 KSA심판장과 주최 측과 상의 후 미팅에 관해 사전 공지. 모든 인원과 참가 팀 멤버들을 등록시키고 임원들에게 대회 관련 협정에 관해 알리고, 경영상 의문점에 관해 명확히 하고 심판 및 대회 전반 사항 경영들을 논의하기 위함을 목적으로 한다.</dd>
                                        </dl>
                                        <dl>
                                            <dt>리쉬(레그로프) 사용 방침(안전장비)</dt>
                                            <dd>ISA 대회 기준에 의거 기준하는 위험 관리 예방 차원으로써 KSA는 각 대회에 리쉬 방침을 진행할 의무가 있음. 모든 선수들은 리쉬를 대회 경영진의 관할권 아래에 있는 대회장 범위 내에서 혹은 모든 구역에서 착용해야 함. 상용화된 리쉬는 어느 타입이건 사용가능. 자유 서핑의 경우 리쉬 사용은 선수의 재량이나, 다른 사람에 대한 위험 부담이 있다면 KSA는 리쉬 사용을 필수 권장. <br>대회 시 자유서핑에도 서로의 안전을 위해 리쉬 사용을 필수로 한다.</dd>
                                        </dl>
                                        <dl>
                                            <dt>대회 중 기계 상의 커뮤니케이션 장치 방침</dt>
                                            <dd>KSA는 대회기간 동안 경기 중인 선수와 다른 사람을 연결하는 어떠한 류의 기계 / 전자 커뮤니케이션 장치(메가폰 포함)도 금한다.</dd>
                                        </dl>
                                        <dl>
                                            <dt>심판진</dt>
                                            <dd>
                                                <ol class="ol-korean li-space">
                                                    <li>심판장: 상위 레벨의 심판이 경기 주최자에 의해 고용되며 KSA 심판위원회의 추천을 받음. 심판장의 선정은 KSA 심판위원회 단독 결정임. 심판장들은 유급으로 고용됨.</li>
                                                    <li>나머지 심판들은 KSA 심판위원회에 의해 선정된다. 심판선정은 전문성, 국제성, 지역별 기준에 항목에 기초하여 심판진을 추천. KSA 대회장으로부터 특별한 동의가 없는 한 새로 심판이 추. 가되거나 KSA 심판장에 의해 심판진에서 해고될 수 없다.</li>
                                                    <li>
                                                        심판 선발은 다음 항목에 기초함
                                                        <ol class="ol-lower-alpha">
                                                            <li>국제 대회심판 : KSA 심판 2급 취득자</li>
                                                            <li>국내 대회심판 : KSA 심판 3급 취득자</li>
                                                            <li>클럽대항, 팀 대회심판 : KSA 심판 2급 취득자가 추천하는 자</li>
                                                        </ol>
                                                    </li>
                                                    <li>대회 기간 대회 참여 인원에 따라 심판진을 구성하며 참여 인원이 많을 시 반드시 심판진을 교대로 채점하며 또한 이들 구성은 주기적으로 구성과 심판의 경험 우선으로 균형을 맞추도록 한다. 오랜 시간 채점하는 것은 금지. 이는 심판장의 책임.</li>
                                                    <li>KSA 대회위원회, 경기혁향상위원회는 심판장의 활동을 주시한다.</li>
                                                </ol>
                                            </dd>
                                        </dl>
                                    </div>
                                    
                                    <!--대회 규칙-->
                                    <div class="tab-cont" :class="{ 'active': rulesTabs === 'comRules' }" @click="rulesTabs = 'comRules'">
                                    </div>
                                    
                                    <!--방해-->
                                    <div class="tab-cont" :class="{ 'active': rulesTabs === 'disturb' }" @click="rulesTabs = 'disturb'">
                                    </div>
                                    
                                    <!--대회장의 임무-->
                                    <div class="tab-cont" :class="{ 'active': rulesTabs === 'misson' }" @click="rulesTabs = 'misson'">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <!--심판 경영 및 점수 책정방법-->
                    <div class="tab-cont" :class="{ 'active': tabs === 'point' }">
                        <div class="desc">
                            <h4>심판 경영 및 점수 책정 방법</h4>
                            
                            <div class="tab-container tab-text">
                                <div class="tab">
                                    <button type="button" :class="{ 'on': pointTabs === 'standard' }" @click="pointTabs = 'standard'">심판 기준</button>
                                    <button type="button" :class="{ 'on': pointTabs === 'situation' }" @click="pointTabs = 'situation'">심판 사정 </button>
                                </div>
                                <div class="tab-contents">
                                
                                    <!--심판 기준-->
                                    <div class="tab-cont" :class="{'active': pointTabs === 'standard' }">
                                        
                                        <ol class="ol-number ol-space">
                                            <li>
                                                심판에 사용되는 기준: 
                                                <span class="indent">“잠재 스코어를 최대화하기 위해 선수는 스피드, 파워 및 플로우를 이용해 파도에서 가장 중요하다고 여겨지는 섹션에서 제일 화려한 기술을 선보여야 한다. 혁신적/진보적인 서핑은 다양한 기술 레퍼토리와 마찬가지로 점수를 매길 때 중요하게 고려되는 사항이다. 난이도 가장 높은 기술을 선보이며 파도를 탄 선수에게는 더 높은 점수가 주어질 것.</span><br>
                                                심판에 사용되는 기준: 
                                                <span class="indent-1">“잠재 스코어를 최대화하기 위해 선수는 스피드, 파워 및 플로우를 이용해 파도에서 가장 중요하다고 여겨지는 섹션에서 제일 화려한 기술을 선보여야 한다.</span>
                                            </li>
                                            <li>각 경기의 심판단들은 5명의 심판들로 구성되며, 더 큰 심판진들로부터 로테이션됨. 풀타임 구성의 대회를 이행하기 위해서는 최소 7인의 심판. <br>휴식 없이 경기 3회 이상 심판 근무 보도록 강요하지 않음. 각 심판 패널은 섹션6에 기술된대로 의무를 이행하는 심판장의 재량 아래 심판을 볼 것.</li>
                                            <li>심판들은 반드시 경기 시작 적어도 15분 전에 심판장에게 체크 받아야 함. 이는 파도에 대한 객관적 견지를 습득하고 서핑 기준을 명확하게 하기 위함임. </li>
                                            <li>심판 수와 경기 수는 반드시 명확히 심판 시트에 들어가 있어야 함. </li>
                                            <li>점수가 확실하지 않거나 부정확하여 변경된다면 이는 반드시 선을 그어 잘못된 사항을 지우고 정확한 점수를 다음 블록에 명기. </li>
                                            <li>심판들은 시트를 합산하지 않고 시트를 경기 직후 제출할 것. </li>
                                            <li>각 심판은 반드시 최선을 다해 임할 것. 경기에 최대로 집중하는 것이 필수적이며 사적으로 치우침 없이 최고의 효율성을 발휘함이 특별히 요구되는 바임. </li>
                                            <li>심판은 각 경기자가 탄 모든 파도를 빠짐없이 점수 처리할 것. </li>
                                            <li>1/10 증분 기준으로 나누어져 0.1점에서 10점까지 스코어 처리하게 됨. </li>
                                            <li>심판은 섹션4에 명시된 대로 방해 상황을 판정하는 데 의무가 있음.</li>
                                            <li>심판은 시각적으로 분리되어 있을 것. 심판들이 파도 스코어나 방해 판정 등을 서로 논의하지 않도록 확실히 하는 것이 심판장의 의무임. </li>
                                            <li>심판은 전산이나 매뉴얼 시트에서 스코어 또는 방해 판정을 변경하지 않아야 함. 실수가 생긴 경기에서는 심판은 이를 수정사항에 관여하는 심판장에게 반드시 알릴 것. 심판은 수정/변경 사항에 머리글자로 서명할 것. 컴퓨터 시스템이 심판 과정에 사용되는 경우 심판장만이 점수를 수정할 수 있음. </li>
                                            <li>심판 과정에서 놓친 부분이 있을 시 반드시 시트의 칸 안에 “M”을 표기하고 심판장에게 알림. 심판장은 스코어를 그전 라이딩과 비교하고 다른 심판들의 시트를 참고하여 점수를 부여할 것. </li>
                                            <li>결승전에 투입되는 심판들은 전 대회에 걸쳐 높은 수준의 일관성을 보여준 심판들. </li>
                                            <li>배당받은 임무를 모두 수행한 심판들은 그들이 맡은 마지막 경기의 총계가 나오고 이의제기가 모두 처리될 때까지 대회장 내 가까운 지역에 남아 있을 것.</li>
                                            <li>심판은 타뷸레이터(도표 작성자)가 모든 자료 입력을 끝낼 시점까지 기다릴 것. </li>
                                            <li>선수의 찬스에 관해 공중에게나 미디어 또는 다른 경기자에게 코멘트해서는 안되며 이를 어긴 심판은 심판단에서 면직되며 대회장과의 논의 후 심판장에 의해 다른 조치가 취해질 수 있음. </li>
                                            <li>심판 통계치는 매일 수집될 것(섹션7 참고). 일관성을 유지하지 못한 것으로 판명된 심판은 심판단에서 제외되며 다른 대회 책무로 배정됨(예: 서포터-감시원). 이는 어느 시점이건 발생할 수 있는 상황이며 심판장의 추천에 따라 대회장에 의해 이행될 것. </li>
                                            <li>때때로 특이점에 따른 오류가 심판 과정에서 일어남. 이는 타이밍과 심판 스코어를 포함. 대회장은 검정받은 관측자들(심판장, 심판, 비번인 심판, 스포터, 기타 임원 등)과 상황을 논의할 수 있음. 이들 관측자들은 논의되는 상황 및 사건을 목격하고 이들 특수한 환경에 대해 개별적으로 판단할 것.</li>
                                            <li>스탠드 업 패들 서프: 스탠드 업 패들 서프(SUP) 퍼포먼스는 룰북의 정상의 판정 기준을 따름. 그러나 심판단은 효과적인 수단으로써 패들을 분명히 사용하여, 결과적으로 매우 과격한 움직임을 선보인 기술에 대해 높은 레벨의 기술로 점수를 매길 것. </li>
                                            <li>심판 구역
                                                <ul class="indent-2">
                                                    <li>심판들, 서포터, 해설자, 심판장은 반드시 시야에 제한이 없이 경기자가 타는 파도를 전체 너비로 관측이 언제든 가능한 곳에 자리할 것.</li>
                                                    <li>측면 뷰 또는 파도에 대한 정확하고 적절한 전망이 제공되지 않는 상황에는 용인되지 않음.</li>
                                                    <li>고정된 구조물(포디움)이 지정된 장소에 있으면 이 구조물은 반드시 대회장 및 심판장과 논의를 거쳐 설립될 것.</li>
                                                    <li>대회 장소 변경 시 심판은 선수들이 임시 구조물들을 필요시 설치하더라도 라이딩 할 파도가 전체적으로 가장 잘 보이는 유리한 위치에 자리 잡을 것. </li>
                                                    <li>심판진은 반드시 방수 처리 및 방음 처리된 시설을 제공 받을 것. </li>
                                                    <li>가능하다면 심판진은 서로 시각적으로 분리된 장소에 배치될 것.</li>
                                                </ul>
                                            </li>
                                        </ol>
                                    </div>
                                    
                                    <!--심판 사정-->
                                    <div class="tab-cont" :class="{ 'active': pointTabs === 'situation' }">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>  
    </div>
</template>

<script setup>
    import { ref } from 'vue'
	import { useRouter } from 'vue-router'

    const tabs = ref('normal')
    const rulesTabs = ref('match')
    const pointTabs = ref('standard')
    const router = useRouter()

    //Tab
    const activeTabId = ref(1)

    const setTab = (tabId) => {
        activeTabId.value = tabId
		if (tabId == 1) {
			router.push({ path : '/match-rule' })
		}	
		if (tabId == 2) {
			router.push({ path: '/match-list' })
		}
        if (tabId == 3) {
            router.push({ path: '/match-ranking' })
        }
    }
</script>

<style></style>