<template>
    <!--하단-->
    <div class="board-bottom float-wrap">
        <div class="board-bottom-left">
            <div class="page-total">
                <span>페이지</span>
                <em>{{pagingBean?.pageStartNo > 0 ? pagingBean?.pageNo : 0}}/{{pagingBean?.totalPageCount}}</em>
            </div>
        </div>
        <div class="board-bottom-middle">
            <div class="paging">
                <div class="paging-btns">
                    <button type="button" class="direction" v-if="pagingBean?.pageNo > 1" @click="gridLoad(1)">
                        <img src="@/assets/images/icon/icon-paging-first.png" alt="처음">
                    </button>
                    <button type="button" class="direction" v-if="pagingBean?.pageStartNo -1 > 1" @click="gridLoad(pagingBean?.pageStartNo -1)">
                        <img src="@/assets/images/icon/icon-paging-prev.png" alt="이전">
                    </button>
                    <button v-for="num of pageGroupArr" :key="num" type="button" :class="pagingBean?.pageNo == num ? 'on' : ''" @click="gridLoad(num)">
                        {{ num }}
                    </button>
                    <button type="button" class="direction" v-if="pagingBean?.pageEndNo + 1 < pagingBean?.totalPageCount" @click="gridLoad(pagingBean?.pageEndNo + 1)">
                        <img src="@/assets/images/icon/icon-paging-next.png" alt="다음">
                    </button>
                    <button type="button" class="direction" v-if="pagingBean?.pageNo < pagingBean?.totalPageCount" @click="gridLoad(pagingBean?.totalPageCount)" >
                        <img src="@/assets/images/icon/icon-paging-last.png" alt="마지막">
                    </button>
                </div>
            </div>
        </div>

        <div class="board-bottom-right">
            <button class="btn-sm btn-primary" @:click="writeBoard(editorType,boardType)" v-if="isAdmin">글쓰기</button>
        </div>

    </div>
</template>

<script setup>
    import { getCookie } from '@/util/util';
    import { computed } from 'vue'
    import { useStore } from 'vuex'
    import { useRoute } from 'vue-router'
    import { onMounted, onUnmounted } from 'vue'

    const store = useStore()
    const route = useRoute()   
	const cookie = getCookie(document.cookie)
    
    const props = defineProps({
        editorType : String,
        boardType : Number
    })

    const isAdmin = computed(() => {
        return store.state.temp.isAdmin
    })

    const emit = defineEmits(['getGridData'])

    const pagingBean = computed(() => {
        return store.state.grid.pagination 
    })

    const pageGroupArr = computed(() => {
        const arr = []
        const start = store.state.grid.pagination.pageStartNo
        const end = store.state.grid.pagination.pageEndNo
        if(start > 0) {
            for (let i = start ; i <= end ; i++) {
                arr.push(i)
            }
        }
        
        return arr
    })

    const gridLoad = (pageNo) => {
        store.commit('temp/setSearchParam', {key : 'pageNo', value : pageNo})
        emit('getGridData')
    }

    const writeBoard = (editorType, boardType) => {
        //bdTypeCd, editorType : 대회정보, 공지사항 구분
        const url = process.env.VUE_APP_API_URL + "/" + editorType + "/editor?bdTypeCd=" + boardType + '&sessionId=' + cookie.sessionId 
        const popup = window.open( url
            , '_blank'
            , 'chrome=1 ,centerscreen=1, innerWidth=1180, innerHeight=720'
            , false)
    }
    const getMessgage = (e) => {
		if(e.origin == process.env.VUE_APP_API_URL) {
			getGridData()
		}
	}

    onMounted(async () => {
        await store.dispatch('detail/fetchBoardDetail', { bdNo: route.query?.no, bdTypeCd: route.query?.boardType })
        // 글쓰기, 수정하기 이벤트리스너 체크
        window.addEventListener('message', getMessgage)
    })

    onUnmounted(() => {
        // 페이지 닫힐 때 해당 리스너 삭제
        window.removeEventListener('message', getMessgage)
    })    

</script>

<style>

</style>