<template>
    <dl class="board-cell accent" v-if="pageData?.prevNo != null">
        <dt><img src="@/assets/images/icon/icon-prev.png">&nbsp;&nbsp;이전글</dt>
        <dd>
            <div class="board-cell-box">
                <p><a href="#" @click="goPrev(pageData.prevNo)">
                    {{pageData?.prevTitle}}
                </a></p>
                <span>{{pageData?.prevDt}}</span>
            </div>
        </dd>
    </dl>
    <dl class="board-cell accent" v-if="pageData?.nextNo != null">
        <dt>다음글&nbsp;&nbsp;<img src="@/assets/images/icon/icon-next.png"></dt>
        <dd>
            <div class="board-cell-box">
                <p><a href="#" @click="goNext(pageData.nextNo)">
                    {{ pageData?.nextTitle }}
                </a></p>
                <span>{{ pageData?.nextDt }}</span>
            </div>
        </dd>
    </dl>
</template>

<script setup>
    const props = defineProps({
        pageData: Object
    });
    
    const emit = defineEmits(['goPage']);
    
	const goPrev = () => {
		let param = {
			no: props.pageData.prevNo,
			boardType: props.pageData?.bdTypeCd
		}
        emit("goPage", param)
	}
	
	const goNext = () => {
        
		let param = {
			no: props.pageData.nextNo,
			boardType: props.pageData?.bdTypeCd
		}
        emit("goPage", param)
	}

</script>

<style scoped></style>
