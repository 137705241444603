import { getBoardDetail, insertReply, updateReply, deleteReply } from '@/api/detail'
export default {
    namespaced: true,
    state : {
        detailData : {}
    },
    mutations : {
        setDetailData(state, payload) {
            state.detailData = payload
        },
        clearDetailData(state) {
            state.detailData = {}
        }
    },
    actions : {
        async fetchBoardDetail ({state, commit}, payload) {
            commit('clearDetailData')
            let rtn = {}
            rtn = await getBoardDetail(payload)

            commit('setDetailData', rtn?.detail)

        },
        async fetchBoardInsertReply ({state, commit}, payload) {
            let rtn = {}
            rtn = await insertReply(payload)
        },
        async fetchBoardUpdateReply ({state, commit}, payload) {
            let rtn = {}
            rtn = await updateReply(payload)
        },
        async fetchBoardDeleteReply ({state, commit}, payload) {
            let rtn = {}
            rtn = await deleteReply(payload)
        },
    }
}    