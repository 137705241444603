import {
  getNoticeList,
  getMatchList,
  getDevelopmentList,
  getContestList,
  getGalleryList,
  getMemberList,
  getMemberBoardList,
  getNewsList,
  getFeeList,
  getJoinInfoList,
  getRankingList
} from "@/api/grid";

import adminMemberJson from '@/models/gridInfo/adminMember.json'
import adminDevelopJson from '@/models/gridInfo/adminDevelopment.json'
import developmentJson from '@/models/gridInfo/development.json'
import noticeJson from '@/models/gridInfo/notice.json'
import newsJson from '@/models/gridInfo/news.json'
import matchJson from '@/models/gridInfo/match.json'
import galleryJson from '@/models/gridInfo/gallery.json'
// import memberBoardJson from '@/models/gridInfo/rank.json'
import feeJson from '@/models/gridInfo/fee.json'
import joinInfoJson from '@/models/gridInfo/joinInfo.json'
import memberBoardJson from "@/models/gridInfo/memberBoard.json"
import rankingJson from '@/models/gridInfo/ranking.json'
import adminContestJson from '@/models/gridInfo/adminContest.json'



export default {
  namespaced: true,
  state: {
    gridData: [],
    pagination: {
      pageNo: 1,
    },
  },
  mutations: {
    setGridData(state, payload) {
      state.gridData.push(payload);
    },
    clearGridData(state) {
      state.gridData.length = 0;
    },
    setPagination(state, payload) {
      state.pagination = payload;
    },
    clearPagination(state) {
      state.pagination = {
        pageNo: 1,
      };
    },
  },
  actions: {
    async fetchNotice({ state, commit }, payload) {
      commit("clearGridData");

      let rtn = {};
      rtn = await getNoticeList(payload);

      for (const vo of rtn?.boardList) {
        let dataObj = {};
        for (const info of noticeJson.cell) {
          dataObj[`${info.key}`] = vo[`${info.key}`];
        }
        commit("setGridData", dataObj);
      }

      commit("setPagination", rtn?.pagingBean);
    },
    async fetchNews({ state, commit }, payload) {
      commit("clearGridData");

      let rtn = {};
      rtn = await getNewsList(payload);

      for (const vo of rtn?.boardList) {
        let dataObj = {};
        for (const info of newsJson.cell) {
          dataObj[`${info.key}`] = vo[`${info.key}`];
        }
        commit("setGridData", dataObj);
      }

      commit("setPagination", rtn?.pagingBean);
    },
    async fetchMatch({ state, commit }, payload) {
      commit("clearGridData");

      let rtn = {};
      rtn = await getMatchList(payload);

      for (const vo of rtn?.boardList) {
        let dataObj = {};
        for (const info of matchJson.cell) {
          dataObj[`${info.key}`] = vo[`${info.key}`];
        }
        commit("setGridData", dataObj);
      }
      commit("setPagination", rtn?.pagingBean);
    },
    async fetchDevelopment({ state, commit }, payload) {
      commit("clearGridData");

      let rtn = {};
      rtn = await getDevelopmentList(payload);

      for (const vo of rtn?.boardList) {
        let dataObj = {};
        for (const info of developmentJson.cell) {
          dataObj[`${info.key}`] = vo[`${info.key}`];
        }
        commit("setGridData", dataObj);
      }
      commit("setPagination", rtn?.pagingBean);
    },
    async fetchGallery({ state, commit }, payload) {
      commit("clearGridData");

      let rtn = {};
      rtn = await getGalleryList(payload);

      for (const vo of rtn?.boardList) {
        let dataObj = {};
        for (const info of galleryJson.cell) {
          dataObj[`${info.key}`] = vo[`${info.key}`];
        }
        commit("setGridData", dataObj);
      }
      commit("setPagination", rtn?.pagingBean);
    },
    async fetchAdminMember({ state, commit, dispatch }, payload) {
      commit("clearGridData");

      let rtn = {};
      rtn = await getMemberList(payload);

      if (rtn.result == "ok") {
        for (const vo of rtn?.data) {
          let dataObj = {};
          for (const info of adminMemberJson.cell) {
            dataObj[`${info.key}`] = vo[`${info.key}`];
          }
          commit("setGridData", dataObj);
        }
        commit("setPagination", rtn?.pagingBean);
      } else {
        alert(rtn.resultMsg);
        dispatch("temp/clearSessionOut", null, { root: true });
      }
    },
    async fetchAdminDevelop({ state, commit }, payload) {
      commit("clearGridData");

      let rtn = {};
      rtn = await getDevelopmentList(payload);

      for (const vo of rtn?.boardList) {
        let dataObj = {};
        for (const info of adminDevelopJson.cell) {
          dataObj[`${info.key}`] = vo[`${info.key}`];
        }
        commit("setGridData", dataObj);
      }
      commit("setPagination", rtn?.pagingBean);
    },
    async fetchAdminContest({ state, commit }, payload) {
      commit("clearGridData");

      let rtn = {};
      rtn = await getContestList(payload);

      for (const vo of rtn?.boardList) {
        let dataObj = {};
        for (const info of adminContestJson.cell) {
          dataObj[`${info.key}`] = vo[`${info.key}`];
        }
        commit("setGridData", dataObj);
      }
      commit("setPagination", rtn?.pagingBean);      
    },
    // async fetchAdminDevelop({ state, commit }, payload) {
    //   commit("clearGridData");

    //   let rtn = {};
    //   rtn = await getDevelopmentList(payload);

    //   for (const vo of rtn?.boardList) {
    //     let dataObj = {};
    //     for (const info of adminDevelopJson.cell) {
    //       dataObj[`${info.key}`] = vo[`${info.key}`];
    //     }
    //     commit("setGridData", dataObj);
    //   }
    //   commit("setPagination", rtn?.pagingBean);
    // },
    async fetchMemberList({ state, commit }, payload) {
      commit("clearGridData");

      let rtn = {};
      rtn = await getMemberBoardList(payload);

      for (const vo of rtn?.boardList) {
        let dataObj = {};
        for (const info of memberBoardJson.cell) {
          dataObj[`${info.key}`] = vo[`${info.key}`];
        }
        commit("setGridData", dataObj);
      }
      commit("setPagination", rtn?.pagingBean);
    },
    async fetchJoinInfo({ state, commit }, payload) {
      commit("clearGridData");

      let rtn = {};
      rtn = await getJoinInfoList(payload);

      for (const vo of rtn?.boardList) {
        let dataObj = {};
        for (const info of joinInfoJson.cell) {
          dataObj[`${info.key}`] = vo[`${info.key}`];
        }
        commit("setGridData", dataObj);
      }
      commit("setPagination", rtn?.pagingBean);
    },
    async fetchFee({ state, commit }, payload) {
      commit("clearGridData");

      let rtn = {};
      rtn = await getFeeList(payload);

      for (const vo of rtn?.boardList) {
        let dataObj = {};
        for (const info of feeJson.cell) {
          dataObj[`${info.key}`] = vo[`${info.key}`];
        }
        commit("setGridData", dataObj);
      }
      commit("setPagination", rtn?.pagingBean);
    },
    async fetchRanking({ state, commit }, payload) {
      commit("clearGridData");

      let rtn = {};
      rtn = await getRankingList(payload);

      for (const vo of rtn?.boardList) {
        let dataObj = {};
        for (const info of rankingJson.cell) {
          dataObj[`${info.key}`] = vo[`${info.key}`];
        }
        commit("setGridData", dataObj);
      }
      commit("setPagination", rtn?.pagingBean);
    },
  },
};    