<template>

	<div class="subtop">
		<div class="subtop-cont">
			<div class="subtop-title title-2">
				<p>자격증</p>
				<span>대한서핑협호의 자격증 정보를 확인할 수 있습니다.</span>
			</div>
		</div>
		<img class="subtop-img" src="@/assets/images/img/subtop-1.png">
	</div>
    <div class="sub-menu">
		<ul class="float-wrap">
			<li><router-link to="/development-level">자격증 안내</router-link></li>
			<li><router-link to="/development-coaching">Coaching and Intruction</router-link></li>
			<li class="on"><router-link to="/development-rescue">KSA surf rescue and CPR Test</router-link></li>
			<li><router-link to="/development-list">자격신청</router-link></li>
		</ul>
	</div>

	<!--서브내용-->
	<div class="sub-wrap">
		<div class="sub-cont">
			<p class="title-sub">Level Test</p>
			<div style="padding: 50px;
						box-shadow:4px 4px 10px 0px rgba(124,124,124,.5)">
				<img :src="apiBaseUrl + '/images/sub/development/development_img03.png'" alt="서핑클럽">
				<div style="margin-top: 30px;">
					<h3 class="back-img"
						:style="{'background-image':'url(' + apiBaseUrl + '/images/sub/list_style.png)'}"
						>KSA Surf rescue</h3>
					<p class="txt">
						대한서핑협회에서는 클럽 강사 인증 레벨인 KSA Level3 이상 취득자를 위한 Surf rescue 과정을 적용 교육하며, Surf rescue 과정은 ISA의 인증방식에 따라 대한서핑협회의 헤드 인스트럭터가 교육 테스트하게 됩니다.<br>
						교육시간 및 교육 금액은 추후 공지하게 되며 자격은 ISA(국제서핑협회)와 KSA(대한서핑협회)가 보장하는 프로그램입니다.
					</p>
				</div>
				<br>
				<br>
				<div class="list_box mt30">
					<h3 class="back-img"
						:style="{'background-image':'url(' + apiBaseUrl + '/images/sub/list_style.png)'}"
						>CPR Test</h3>
					<p class="txt mt30">
						대한서핑협회에서는 클럽 강사 인증 레벨인 KSA Level3 이상 취득자를 위한 대한적십자사의 심폐소생술과정을 필수 과정으로 채택하여 실행하고 있습니다. 이에 KSA Level 3을 취득하실 분께서는 필히 자격을 이수 하시기 바라며 대한서핑협회에서는 대한적십자사의 심폐소생술과정의 스케줄 외 대한서핑협회에서 개별 진행 함으로 스케줄을 항시 체크바랍니다.
					</p>
					<p class="txt" style="border-top: 1px solid #ccc; padding: 30px 0 0 30px">
						심장마비는 암(癌) 등 기타 질병과 달리 산소 부족으로 뇌 손상이 오는 5분 이내에 기초적인 심폐소생술과 전기쇼크 치료를 받으면 대부분 회복될 수 있습니다. 하지만 우리나라에서는 가족, 목격자 등의 교육과 인식 부족으로 현장에서 심폐소생술이 이뤄지는 경우가 3 ~ 4%에 머물러 있는 실정입니다. 이 때문에 살 수 있는 환자들이 허망한 죽음을 맞거나, 살더라도 뇌 손상 등 심각한 후유증에 시달리게 됩니다. 심폐 소생술은 일상생활에서 심장 쇼크가 발생하였을 때 즉각적이고 올바를 처치를 행할 수 있도록 하는 지식과 기능을 말합니다. 심폐소생술과정은 응급처치법의 원리, 심장발잦의 원인과 증상, 성인, 어린이, 영아의 인공호흡법과 심폐소생술, 자동제세동기(AED)의 사용법, 기도폐쇄 처치법을 배우는 과정으로서 고등학교 1년 이상이면 누구나 강습에 참가할 수 있으며, 강습시간은 6시간 입니다.<br><br>
						강습시간 참가대상 교육내용 교제 정원 수료증 유효기간<br>
						6시간 고등학교 1년 이상 - 응급처치법의 원리<br>
						- 심폐소생술<br>
						- 자동제세동기의 사용<br>
						- 기도폐쇄<br>
						&nbsp;&nbsp;&nbsp;심폐소생술 10~20명 1년
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	
	const apiBaseUrl = process.env.VUE_APP_API_URL

</script>

<style>
	.back-img {
		padding-left: 30px;
		font-size:20px;
		color:#0480b6;
		font-weight:600;
		background-repeat: no-repeat;
	}
	.txt {
		margin-top: 10px;
		padding-left: 30px;
		font-size: 14px;
		color: #333;
		line-height: 24px;
	}
</style>