<template>
  <Home />
</template>

<script>
import Home from '@/layouts/home'
import { onBeforeMount } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'App',
  components: {
    Home,
  },
  setup(){
    const store = useStore()
    onBeforeMount(() => {
      store.dispatch('treeMenu/fetch')
    })
  }
}
</script>

<style>
</style>
