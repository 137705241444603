<template>
    <div class="header">
        	<div class="header-wrap wrap-md float-wrap">
        		<h1 class="logo"><a href="#" @click="homeClick"><img src="@/assets/images/common/logo.png" alt="KSA"></a></h1>
        		<button type="button" class="header-menu-btn toggle-btn">메뉴</button>
        		<div class="header-menu float-wrap">
					<div class="gnb" v-if="!isAdmin">
						<ul class="float-wrap">
                            <HeaderItem v-for="(item, index) in tree"
                                :key="item.key"
                                :index="index"
                                :menu="item.name"
                                :routeUrl="item.pathName"
                            />
						</ul>
					</div>
                    <div class="gnb2" v-if="isAdmin">
						<ul class="float-wrap">
                            <HeaderItem v-for="(item, index) in tree"
                                :key="item.key"
                                :index="index"
                                :menu="item.name"
                                :routeUrl="item.pathName"
                            />
						</ul>
					</div>
					<div class="header-account float-wrap">
                        <router-link to="/member-policy" v-if="!isLogin">회원가입</router-link>
                        <router-link to="/member-login" class="header-login btn btn-primary btn-sm" v-if="!isLogin">
                            <img src="@/assets/images/icon/icon-user-white.png">
                            로그인
                        </router-link>
					</div>

                    <div class="header-account2 float-wrap" v-if="isLogin">
                        <router-link to="/mypage">{{userName}} 님 | </router-link>
                        <router-link to="/admin-member" v-if="isAdmin"> 관리자 페이지 | </router-link>
                        <a href="#" class="header-login btn btn-primary btn-sm" @click="logout">로그아웃</a>
                    </div>
        		</div>
        		<div class="header-menu-bg"></div>
        	</div>
        </div>
</template>

<script setup> 
    import HeaderItem from './headerItem'
    import { useStore } from 'vuex'
    import { computed } from 'vue'
    import { delCookie } from '@/util/util'
    import router from '@/router'

    const store = useStore()

    let { tree } = store.state.treeMenu
    
	const isAdmin = computed (() => {
		return store.state.temp.isAdmin
	})

    const isLogin = computed (() => {
        return store.state.temp.isLogin
    })

    const userName = computed (() => {
        return store.state.temp.userInfo.userName
    })
    // let { selectArea } = store.state.officeInfo

    const homeClick = () => {
        store.commit('temp/setIsMain', false)
        router.push({
            name: 'Home'
        }) 
    }

    const logout = () => {
        if(confirm('로그아웃 하시겠습니까?')) {
            delCookie("sessionId")
            store.commit('temp/setIsLogin', false)
            store.commit('temp/setIsAdmin', false)
            store.commit('temp/clearUserInfo')
        }
    }


    
</script>

<style scoped>


</style>