<template>
    <div class="subtop">
        <div class="subtop-cont">
            <div class="subtop-title title-2">
                <p>ABOUT KSA</p>
                <span>대한서핑협회를 소개 합니다.</span>
            </div>
        </div>
        <img class="subtop-img" src="@/assets/images/img/subtop-1.png">
    </div>
    <div class="sub-menu">
        <ul class="float-wrap">
            <li><router-link to="/about-greeting">인사말</router-link></li>
            <li><router-link to="/about-ksa">협회소개</router-link></li>
            <li><router-link to="/about-organization">조직도</router-link></li>
            <li><router-link to="/about-rules">관련규정</router-link></li>
            <li class="on"><router-link to="/about-disclosure">경영공시</router-link></li>
        </ul>
    </div>
    <div class="sub-wrap sub-wrap-grey">
        <div class="sub-cont">
            <div class="board">
                <div class="board-top float-wrap">
                    <div class="board-top-left">
                        <p class="cont-total">전체 <span>124</span></p>
                    </div>
                    <div class="board-top-right">
                        <div class="search-box">
                            <input type="text" placeholder="검색어를 입력하세요">
                            <button type="button"><img src="@/assets/images/icon/icon-search.png" alt="검색"></button>
                        </div>
                    </div>
                </div>
                <div class="board-list table-overflow-sm">
                    <table>
                        <colgroup>
                            <col style="width:auto;">
                            <col style="width:160px;">
                        </colgroup>
                        <tr>
                            <th class="t-left">제묵</th>
                            <th class="t-left">다운받기</th>
                        </tr>
                        <tr>
                            <td><a href="#">대한체육회 종목단체가입승인 규정</a></td>
                            <td class="tdright"><button type="button" class="btn btn-sm btn-basic btn-icon-right">PDF<img src="@/assets/images/icon/icon-download.png"></button></td>
                        </tr>
                        <tr>
                            <td><a href="#">대한체육회 종목단체가입승인 규정</a></td>
                            <td class="tdright"><button type="button" class="btn btn-sm btn-basic btn-icon-right">PDF<img src="@/assets/images/icon/icon-download.png"></button></td>
                        </tr>
                        <tr>
                            <td><a href="#">대한체육회 종목단체가입승인 규정</a></td>
                            <td class="tdright"><button type="button" class="btn btn-sm btn-basic btn-icon-right">PDF<img src="@/assets/images/icon/icon-download.png"></button></td>
                        </tr>
                    </table>
                </div>
                <div class="board-bottom float-wrap">
                    <div class="board-bottom-left">
                        <div class="page-total">
                            <span>페이지</span>
                            <em>1/12</em>
                        </div>
                    </div>
                    <div class="board-bottom-middle">
                        <div class="paging">
                            <div class="paging-btns">
                                <button type="button" class="direction"><img src="@/assets/images/icon/icon-paging-first.png" alt="처음"></button>
                                <button type="button" class="direction"><img src="@/assets/images/icon/icon-paging-prev.png" alt="이전"></button>
                                <button type="button" class="on">1</button>
                                <button type="button">2</button>
                                <button type="button">3</button>
                                <button type="button">4</button>
                                <button type="button">5</button>
                                <button type="button" class="direction"><img src="@/assets/images/icon/icon-paging-next.png" alt="다음"></button>
                                <button type="button" class="direction"><img src="@/assets/images/icon/icon-paging-last.png" alt="마지막"></button>
                            </div>
                        </div>
                    </div>
                    <div class="board-bottom-right">
                        <select class="view-select">
                            <option value="10">10</option>
                            <option value="10">10</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>  
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>