<template>
    <!--게시판리스트-->
    <div class="board-list board-list-sm table-overflow-md">
        <table>
            <colgroup>
                <col v-for="(item, index) of gridInfo.colstyle" :width="item" :key="index">
            </colgroup>
            <tr>
                <th v-for="(item, index) of gridInfo.header" :key="index">
                    {{ item }}
                </th>
            </tr>
            <tr v-for="(vo, index) of dataList" :key="index">
                <template v-for="(cnt, idx) in gridInfo.cell" :key="idx">
                    <td :class="cnt.class" style="padding:0%; text-align: center;">

                        <!-- 저장여부 -->
                        <template v-if="cnt.cancel">
                            <button type="button" class="cancelBtn" @click="cancelApply(vo)">신청취소</button>
                        </template>

                        <!-- 저장여부 -->
                        <template v-else-if="cnt.class == 'applyEtc'">
                            <pre>{{vo[cnt.key]}}</pre>
                        </template>

                        <template v-else>
                            {{vo[cnt.key]}}
                        </template>
                    </td>
                </template>
            </tr>
        </table>
    </div>
</template>

<script setup>
    import { useStore } from 'vuex'

    const store = useStore()

    const props = defineProps({
        gridInfo : Object,
        dataList : Array
    })

	const cancelApply = async (data) => {
        
		if(!confirm("신청 데이터는 삭제 됩니다. 삭제 하시겠습니까?")) {
			return;
		}
		
		let params = {
			bdApplyNo : data.bdApplyNo,
			userId : data.userId
		}

        await store.dispatch('admin/cancelApply', params)
        await store.dispatch('admin/fetchAdminApply', { bdNo : data.bdNo, pageNo : 1})
	};


</script>

<style>
.cancelBtn{width: 80px; text-align: center; padding: 0; line-height: 35px; text-decoration: none; border:1px solid #b7b7b7;}
.applyEtc {max-width: 300px; overflow: auto;}
</style>