<template>
    <input 
        :class="propClass" 
        :value="value" 
        :placeholder="placeholder" 
        @input="updateInput">
</template>

<script>
export default {
    props: {
        propClass: {
            type : String,
            default : ''
        },
        name : {
            type : String,
        },
        value : {
            type : String,
            default : ''
        },
        placeholder : {
            type : String
        },
        index: {
            type: Number
        }
    },
    setup(props, { emit }){
        
        const updateInput = (e) => {
            const obj = {
                index : props.index, 
                type: props.name,
                value: e.target.value
            }
            emit('updateInput', obj)
        }
        
        return {
            updateInput
        }

    }
}
</script>

<style scoped></style>
