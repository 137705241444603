<template>
	<div class="subtop">
		<div class="subtop-cont">
			<div class="subtop-title title-2">
				<p>ABOUT KSA</p>
				<span>대한서핑협회를 소개 합니다.</span>
			</div>
		</div>
		<img class="subtop-img" src="@/assets/images/img/subtop-1.png">
	</div>

	<div class="sub-menu" style="margin-top: 15px;">
        <ul class="float-wrap">
			<!-- <li class="on"><router-link to="/about-greeting">인사말</router-link></li>
			<li><router-link to="/about-ksa">협회소개</router-link></li>
			<li><router-link to="/about-organization">조직도</router-link></li>
			<li><router-link to="/about-rules">시/도협회</router-link></li> -->

			<div class="tab-container tab-box">
				<div class="tab tab-4">
					<button type="button" @click="setTab(1)" class="on">인사말</button>
					<button type="button" @click="setTab(2)" :class="activeTabId == 2 ? 'on' : ''">협회소개</button>
					<button type="button" @click="setTab(3)" :class="activeTabId == 3 ? 'on' : ''">조직도</button>
					<button type="button" @click="setTab(4)" :class="activeTabId == 4 ? 'on' : ''">시/도협회</button>
				</div>
			</div>
		</ul>
	</div>

	<div class="sub-wrap sub-wrap-grey">
		<div class="sub-cont">
			<div class="greeting float-wrap">
				<div class="greeting-img"><img src="@/assets/images/img/img-ceo.png" alt="회장 서종희 사진"></div>
				<div class="greeting-cont">
					<div class="greeting-top">
						<em>Creating</em>
						<span>KSA(대한서핑협회)인사말</span>
					</div>
					<div class="greeting-middle">
						<em>안녕하십니까? <br>대한 서핑협회입니다.</em>
						<p>대한서핑협회는 대한체육회 가입단체이며 2009년 2월에 국제올림픽위원회(IOC)의 협력단체인 국제서핑협회(ISA)의 정식 등록단체로 등록되어 ISA의 규정에 맞추어 활동하고 있습니다.</p> 
						<p>2000년대 초반부터 서핑의 발전과 저변확대를 위해 전국의 체육회와 시.구.군청과 협력하여 서핑클럽 및 시.도서핑협회 지원, 국내외 대회관련 주관 및 선수 육성, 국가대표팀 선발 및 파견 등의 업무를 추진하고 있습니다.</p>
						<span>대한서핑협회는 국내서핑대회를 개최하고 국제서핑대회 참가를 활성화하며 체계적인 훈련을 통한 실력 향상으로 올림픽 및 아시안게임의 서핑종목 메달권 진입을 위해 노력하겠습니다.</span>
						<p>그리고 유소년 선수를 지원하여 초,중,고,대학교에 서핑부 팀을 창단하고 교육사업의 체계화를 통해 지도자, 심판을 위한 교재를 발급하며 서핑스쿨과 서핑샵을 지원하여 안전하고 건전한 서핑 종목을 활성화하고 대한민국 서핑 발전을 위해 노력할 것입니다.</p>
					</div>
					<div class="greeting-end">
						<p>감사합니다.</p>
						<span>회장<em>서종희</em></span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { ref } from 'vue'
	import { useStore } from 'vuex'
	import { useRouter } from 'vue-router'

	const store = useStore()
	const router = useRouter()

	let { tree } = store.state.treeMenu
	// console.log(tree)
	
	//탭
	const activeTabId = ref(1)

	const setTab = (tabId) => {
		activeTabId.value = tabId
		if(tabId == 1) {
			router.push({ path : '/about-greeting' })
		}	
		if (tabId == 2) {
			router.push({ path: '/about-ksa' })
		}
		if (tabId == 3) {
			router.push({ path: '/about-organization' })
		}
		if (tabId == 4) {
			router.push({ path: '/about-rules' })
		}
	} 

</script>

<style>

</style>