<template>
    <div class="subtop">
        <div class="subtop-cont">
            <div class="subtop-title title-2">
                <p>KSA CLUB</p>
                <span>회원자격에 관한 상세한 정보를 확인 하세요</span>
            </div>
        </div>
        <img class="subtop-img" src="@/assets/images/img/subtop-1.png">
    </div>
    <div class="sub-menu" style="margin-top: 15px;">
        <ul class="float-wrap" style="width: 80%;">
            <!-- <li class="on"><router-link to="/notice-list">공지사항</router-link></li>
			<li><router-link to="/news-list">뉴스게시판</router-link></li>
			<li><router-link to="/notice-gallery">갤러리</router-link></li> -->

            <div class="tab-container tab-box">
                <div class="tab tab-3">
                    <button type="button" @click="setTab(1)" :class="''">KSA CLUB</button>
                    <button type="button" @click="setTab(2)" :class="''">CLUB LIST</button>
                    <button type="button" @click="setTab(3)" :class="'on'">KSA MEMBER</button>
                </div>
            </div>
        </ul>
    </div>

    <!--서브내용-->
    <div class="sub-wrap">
        <div class="sub-cont">
            <p class="title-sub">회비납부</p>

            <BoardTop :gridInfo="feeJson" @getGridData="getGridData" v-model="searchText" />

            <Grid :gridInfo="feeJson" :dataList="feeList" />

            <Pagination v-if="feeJson.pagination" :gridInfo="feeJson" :editorType="'notice'"
                :boardType="feeJson?.boardType" @getGridData="getGridData" />

        </div>
    </div>
</template>

<script setup>
import BoardTop from '@/components/common/grid/boardtop'
import Grid from '@/components/common/grid/grid'
import Pagination from '@/components/common/grid/pagination'
import feeJson from '@/models/gridInfo/fee.json'
import { ref, computed, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

const store = useStore()
const router = useRouter()

const searchText = ref('')

const feeList = computed(() => {
    return store.state.grid.gridData
})

const searchParam = computed(() => {
    return store.state.temp.searchParam
})

const getGridData = async () => {
    let param = { pageNo: searchParam.value.pageNo, search: searchText.value }
    await store.dispatch('grid/fetchFee', param)
}

const getMessgage = (e) => {
    if (e.origin == process.env.VUE_APP_API_URL) {
        getGridData()
    }
}

onMounted(async () => {
    getGridData()
    // 글쓰기, 수정하기 이벤트리스너 체크
    window.addEventListener('message', getMessgage)
})

onUnmounted(() => {
    // 페이지 닫힐 때 해당 리스너 삭제
    window.removeEventListener('message', getMessgage)
})

//탭
const activeTabId = ref(1)

const setTab = (tabId) => {
    activeTabId.value = tabId
    if (tabId == 1) {
        router.push({ path: '/memberBoard-join' })
    }
    if (tabId == 2) {
        router.push({ path: '/memberBoard-list' })
    }
    if (tabId == 3) {
        router.push({ path: '/memberBoard-fee'})
    }
}
</script>

<style></style>