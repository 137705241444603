import list from       '@/pages/news/list'
import view from       '@/pages/news/view'

export default [
    {
        path: '/news-list',
        name: 'news',
        component: list
    },
    {
        path: '/news-view',
        name: 'news-view',
        component: view
    }
]
