<template>
    <div class="subtop">
        <div class="subtop-cont">
            <div class="subtop-title title-2">
                <p>KSA 후원</p>
                <span>후원사에 관한 상세한 정보를 확인 하세요</span>
            </div>
        </div>
        <img class="subtop-img" src="@/assets/images/img/subtop-1.png">
    </div>
    <div class="sub-menu" style="margin-top: 15px;">
        <ul class="float-wrap" style="width: 80%;">
            <!-- <li class="on"><router-link to="/notice-list">공지사항</router-link></li>
			<li><router-link to="/news-list">뉴스게시판</router-link></li>
			<li><router-link to="/notice-gallery">갤러리</router-link></li> -->

            <!-- <div class="tab-container tab-box">
                <div class="tab tab-1">
                    <button type="button" @click="setTab(1)" :class="'on'">KSA SPONSOR</button>
                </div>
            </div> -->
        </ul>
    </div>

    <!--서브내용-->
    <div class="sub-wrap">
        <div class="sub-cont">
            <!-- <p class="title-sub">KSA SPONSOR</p> -->

            <div class="board">
                <div class="club-img">
                    <img class="" src="@/assets/images/img/ksa-sponsor.jpeg" alt="">
                </div>
            </div>

            <!-- <BoardTop :gridInfo="joinInfoJson" @getGridData="getGridData" v-model="searchText" />

            <Grid :gridInfo="joinInfoJson" :dataList="joinInfoList" />

            <Pagination v-if="joinInfoJson.pagination" :gridInfo="joinInfoJson" :editorType="'notice'"
                :boardType="joinInfoJson?.boardType" @getGridData="getGridData" /> -->

        </div>
    </div>
</template>

<script setup>
import BoardTop from '@/components/common/grid/boardtop'
import Grid from '@/components/common/grid/grid'
import Pagination from '@/components/common/grid/pagination'
import feeJson from '@/models/gridInfo/fee.json'
import { ref, computed, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

const store = useStore()
const router = useRouter()

const searchText = ref('')




//탭
const activeTabId = ref(1)

const setTab = (tabId) => {
    activeTabId.value = tabId
    if (tabId == 1) {
        router.push({ path: '/sponsorList' })
    }
}
</script>

<style></style>